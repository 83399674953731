import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"

export const HeroRow = styled.div`
  justify-content: center;
`

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  @media screen and (max-width: 580px) {
    flex-direction: column;
    height: 140vh;
  }
`
export const IntroLeft = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  margin-top: 10%;
  margin-left: 5%;
`
export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5%;

  > :nth-child(1) {
    color: #ffd100;
    font-weight: bold;
    font-size: 3rem;
  }
  > :nth-child(2) {
    color: #ffd100;
    font-weight: bold;
    font-size: 3rem;
  }
  > :nth-child(4) {
    margin-top: 1rem;
    color: #363a3d;
    font-weight: 400;
    font-size: 16px;
  }
  @media screen and (max-width: 980px) {
    > :nth-child(1) {
      font-size: 2rem;
    }
    > :nth-child(2) {
      font-size: 2rem;
    }
    > :nth-child(4) {
      font-size: 12px;
    }
  }
`

export const IntroRight = styled.div`
  flex: 1;
  position: relative;
  top: 10%;

  > * {
    position: absolute;
    z-index: 1;
  }

  > :nth-child(1) {
    transform: scale(0.8);
    top: 7%;
    margin-left: -5%;
  }
  > :nth-child(2) {
    transform: scale(0.8);
    margin-left: 10%;
    top: -6%;
  }
  > :nth-child(3) {
    transform: scale(0.85);
    margin-left: 4%;
    top: 17%;
  }
  > :nth-child(4) {
    transform: scale(0.4);
    left: -15%;
    top: -15%;
  }
  @media screen and (max-width: 1480px) {
    transform: scale(0.9);
    left: -5%;
  }
  @media screen and (max-width: 1280px) {
    transform: scale(0.7);
    left: -10%;
    top: -5%;
  }
  @media screen and (max-width: 980px) {
    transform: scale(0.5);
    left: -10%;
    top: -15%;
  }
  @media screen and (max-width: 780px) {
    transform: scale(0.4);
    left: -15%;
    top: -10%;
  }
  @media screen and (max-width: 580px) {
    transform: scale(0.7);
    top: -20%;
  }
  @media screen and (max-width: 490px) {
    transform: scale(0.5);
    left: -15%;
    top: -10%;
  }
  @media screen and (max-width: 390px) {
    transform: scale(0.4);
    left: -20%;
    top: -10%;
  }
`
export const BtnLinkJoin = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  margin: 15px 10px;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
