import React from "react"
import {
  LabelSecond,
  ContactContainer,
  Input,
  LabelModel,
  RadioCheck,
  RadioYearContainer,
  YearContainer,
  SubProjectContainer,
  ErrorsText,
} from "./submitElements"
import "./Styles.css"

const FormService = (props) => {
  return (
    <div>
      <ContactContainer>
        <LabelSecond>ประเภทใบรับรองฯ ที่ขอใช้บริการ</LabelSecond>
        <label className="container">
          Personal Certificate โปรดระบุอีเมล์ที่แสดงบนใบรับรอง (Specify E-mail
          which it show in the digital certificate)
          <input
            type="checkbox"
            checked={props.isPersonal}
            onChange={() => props.setPersonal(!props.isPersonal)}
          />
          <span className="checkmark"></span>
        </label>
        {props.isPersonal ? (
          <>
            <LabelModel>*ต้องระบุ E-mail</LabelModel>
            <Input
              type="text"
              placeholder="Email"
              name="email"
              onChange={props.handlePsc}
              value={props.personalCertificate.email}
            />
            {props.errorsPage2.email && (
              <ErrorsText>{props.errorsPage2.email}</ErrorsText>
            )}
            <YearContainer>
              <LabelModel>อายุการใช้บริการ (Valid time in year)</LabelModel>
              <RadioYearContainer>
                <RadioCheck
                  type="radio"
                  name="pyear"
                  value="1"
                  checked={props.personalCertificate.pyear === "1"}
                  onChange={props.handlePsc}
                />
                <LabelModel>1 ปี</LabelModel>
                <RadioCheck
                  type="radio"
                  name="pyear"
                  value="2"
                  checked={props.personalCertificate.pyear === "2"}
                  onChange={props.handlePsc}
                />
                <LabelModel>2 ปี</LabelModel>
                <RadioCheck
                  type="radio"
                  name="pyear"
                  value="3"
                  checked={props.personalCertificate.pyear === "3"}
                  onChange={props.handlePsc}
                />
                <LabelModel>3 ปี</LabelModel>
              </RadioYearContainer>
              {props.errorsPage2.pyear && (
                <ErrorsText>{props.errorsPage2.pyear}</ErrorsText>
              )}
            </YearContainer>
          </>
        ) : null}
        <label className="container">
          Web Server Certificate (สำหรับนำไปใช้งานกับเครื่อง Server)
          อายุการใช้บริการ (Valid time in year)
          <input
            type="checkbox"
            checked={props.isWebServer}
            onChange={() => props.setWebServer(!props.isWebServer)}
          />
          <span className="checkmark"></span>
        </label>
        {props.isWebServer ? (
          <>
            <YearContainer>
              <LabelSecond>
                โปรดระบุรายละเอียดในใบรับรอง เป็นภาษาอังกฤษ (Specify Certificate
                Detail)
              </LabelSecond>
              <LabelModel>Common Name (Domain Name) (CN)</LabelModel>
              <Input
                type="text"
                placeholder="Common Name"
                name="commonName"
                onChange={props.handleWsc}
                value={props.webServerCertificate.commonName}
              />
              {props.errorsPage2.commonName && (
                <ErrorsText>{props.errorsPage2.commonName}</ErrorsText>
              )}
              <LabelModel>Organization Unit (OU)</LabelModel>
              <Input
                type="text"
                placeholder="Organization Unit"
                name="organizationUnit"
                onChange={props.handleWsc}
                value={props.webServerCertificate.organizationUnit}
              />
              {props.errorsPage2.organizationUnit && (
                <ErrorsText>{props.errorsPage2.organizationUnit}</ErrorsText>
              )}
              <LabelModel>Organization (O)</LabelModel>
              <Input
                type="text"
                placeholder="Organization"
                name="organization"
                onChange={props.handleWsc}
                value={props.webServerCertificate.organization}
              />
              {props.errorsPage2.organization && (
                <ErrorsText>{props.errorsPage2.organization}</ErrorsText>
              )}
              <LabelModel>Locality (L) </LabelModel>
              <Input
                type="text"
                placeholder="Locality"
                name="locality"
                onChange={props.handleWsc}
                value={props.webServerCertificate.locality}
              />
              {props.errorsPage2.locality && (
                <ErrorsText>{props.errorsPage2.locality}</ErrorsText>
              )}
              <LabelModel>อายุการใช้บริการ (Valid time in year)</LabelModel>
              <RadioYearContainer>
                <RadioCheck
                  type="radio"
                  name="wyear"
                  value="1"
                  checked={props.webServerCertificate.wyear === "1"}
                  onChange={props.handleWsc}
                />
                <LabelModel>1 ปี</LabelModel>
                <RadioCheck
                  type="radio"
                  name="wyear"
                  value="2"
                  checked={props.webServerCertificate.wyear === "2"}
                  onChange={props.handleWsc}
                />
                <LabelModel>2 ปี</LabelModel>
              </RadioYearContainer>
              {props.errorsPage2.wyear && (
                <ErrorsText>{props.errorsPage2.wyear}</ErrorsText>
              )}
            </YearContainer>
          </>
        ) : null}
        <LabelSecond>
          ประเภทโครงการที่นำใบรับรองฯ ไปใช้งาน (Specify for the specific project
          which use)
        </LabelSecond>
        <label className="container">
          โครงการ NSW เช่น กรมศุลกากร , กรมประมง , อย. , กระทรวงพลังงาน ,
          กรมป่าไม้ และ กรมการค้าต่างประเทศ เป็นต้น
          <input
            type="checkbox"
            checked={
              props.project5 === true || props.project7 === true
                ? props.setProject1(false)
                : props.project1
            }
            onChange={() => props.setProject1(!props.project1)}
            disabled={props.project5 === true || props.project7 === true}
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการ สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
          (คปภ.)
          <input
            type="checkbox"
            checked={
              props.project5 === true || props.project7 === true
                ? props.setProject2(false)
                : props.project2
            }
            onChange={() => props.setProject2(!props.project2)}
            disabled={props.project5 === true || props.project7 === true}
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการกลุ่มสมาคมธนาคารไทย
          <input
            type="checkbox"
            checked={
              props.project5 === true || props.project7 === true
                ? props.setProject3(false)
                : props.project3
            }
            onChange={() => props.setProject3(!props.project3)}
            disabled={props.project5 === true || props.project7 === true}
          />
          <span className="checkmark"></span>
        </label>
        {props.project3 ? (
          <YearContainer>
            <LabelModel>ระบุ ชื่อ-นามสกุล ผู้ใช้งาน เป็นภาษาอังกฤษ</LabelModel>
            <Input
              type="text"
              placeholder="Fisrt Name - Last Name"
              name="p3Details"
              onChange={(e) => props.setP3Details(e.target.value)}
              value={props.p3Details}
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการสำนักงานพาณิชย์จังหวัด
          <input
            type="checkbox"
            checked={
              props.project5 === true || props.project7 === true
                ? props.setProject4(false)
                : props.project4
            }
            onChange={() => props.setProject4(!props.project4)}
            disabled={props.project5 === true || props.project7 === true}
          />
          <span className="checkmark"></span>
        </label>
        {props.project4 ? (
          <YearContainer>
            <LabelModel>ระบุ ชื่อ-นามสกุล ผู้ใช้งาน เป็นภาษาอังกฤษ</LabelModel>
            <Input
              type="text"
              placeholder="Fisrt Name - Last Name"
              name="name"
              onChange={props.handleP4Details}
              value={props.p4Details.name}
            />
            <LabelModel>เลขที่บัตรประชาชนของผู้ใช้งาน</LabelModel>
            <Input
              type="text"
              placeholder="เลขที่บัตร"
              name="idCard"
              onChange={props.handleP4Details}
              value={props.p4Details.idCard}
            />
            <LabelModel>บัตรหมดอายุ</LabelModel>
            <Input
              type="text"
              placeholder="บัตรหมดอายุ"
              name="expireDate"
              onChange={props.handleP4Details}
              value={props.p4Details.expireDate}
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการสำนักงานป้องกันและปราบปรามการฟอกเงิน (ปปง.)
          <input
            type="checkbox"
            checked={props.project5}
            onChange={() => props.setProject5(!props.project5)}
            disabled={props.project7 === true}
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการอื่นๆ
          <input
            type="checkbox"
            checked={
              props.project5 === true || props.project7 === true
                ? props.setProject6(false)
                : props.project6
            }
            onChange={() => props.setProject6(!props.project6)}
            disabled={props.project5 === true || props.project7 === true}
          />
          <span className="checkmark"></span>
        </label>
        {props.project6 ? (
          <YearContainer>
            <LabelModel>ระบุโครงการ</LabelModel>
            <Input
              type="text"
              placeholder="ระบุโครงการ"
              name="p6Details"
              onChange={(e) => props.setP6Details(e.target.value)}
              value={props.p6Details}
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการ RDC Online ของ สวทช.
          <input
            type="checkbox"
            checked={props.project7}
            onChange={() => props.setProject7(!props.project7)}
            disabled={props.project5 === true}
          />
          <span className="checkmark"></span>
        </label>
        {props.project7 ? (
          <YearContainer>
            <LabelSecond>
              โปรดเลือกรูปแบบที่นำไปใช้พร้อมระบุข้อมูลเป็นภาษาอังกฤษ
            </LabelSecond>
            <SubProjectContainer>
              <label className="container">
                แบบที่ 1 บุคคลธรรมดา
                <input
                  type="checkbox"
                  checked={props.isforIndividual}
                  onChange={() =>
                    props.setforIndividual(!props.isforIndividual)
                  }
                  disabled={
                    props.isjuristicPerson === true ||
                    props.isindividualAndJuristic === true ||
                    props.isjuristicAndRank === true
                  }
                />
                <span className="checkmark"></span>
              </label>
              {props.isforIndividual ? (
                <YearContainer>
                  <LabelModel>E-mail ของเจ้าของใบรับรอง (E)</LabelModel>
                  <Input
                    type="text"
                    placeholder="E-mail"
                    name="email"
                    onChange={props.handleP7forIndividual}
                    value={props.p7forIndividual.email}
                  />
                  <LabelModel>ชื่อเจ้าของใบรับรอง (CN)</LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="certificate"
                    onChange={props.handleP7forIndividual}
                    value={props.p7forIndividual.certificate}
                  />
                  <LabelModel>เลขประจำตัวประชาชน (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="idCard"
                    onChange={props.handleP7forIndividual}
                    value={props.p7forIndividual.idCard}
                  />
                  <LabelModel>จังหวัดของเจ้าของใบรับรอง (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7forIndividual}
                    value={props.p7forIndividual.province}
                  />
                </YearContainer>
              ) : null}
              <label className="container">
                แบบที่ 2 นิติบุคคล
                <input
                  type="checkbox"
                  checked={props.isjuristicPerson}
                  onChange={() =>
                    props.setjuristicPerson(!props.isjuristicPerson)
                  }
                  disabled={
                    props.isforIndividual === true ||
                    props.isindividualAndJuristic === true ||
                    props.isjuristicAndRank === true
                  }
                />
                <span className="checkmark"></span>
              </label>
              {props.isjuristicPerson ? (
                <YearContainer>
                  <LabelModel>
                    ชื่อนิติบุคคลเจ้าของใบรับรอง (CN)และ (O)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อนิติบุคคล"
                    name="juristicName"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.juristicName}
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.juristicUID}
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.province}
                  />
                </YearContainer>
              ) : null}
              <label className="container">
                แบบที่ 3 นิติบุคคล + บุคคล
                <input
                  type="checkbox"
                  checked={props.isindividualAndJuristic}
                  onChange={() =>
                    props.setindividualAndJuristic(
                      !props.isindividualAndJuristic
                    )
                  }
                  disabled={
                    props.isforIndividual === true ||
                    props.isjuristicPerson === true ||
                    props.isjuristicAndRank === true
                  }
                />
                <span className="checkmark"></span>
              </label>
              {props.isindividualAndJuristic ? (
                <YearContainer>
                  <LabelModel>E-mail เจ้าหน้าที่ของนิติบุคคล (E)</LabelModel>
                  <Input
                    type="text"
                    placeholder="E-mail"
                    name="functionaryEmail"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.functionaryEmail}
                  />
                  <LabelModel>
                    ชื่อเจ้าหน้าที่นิติบุคคลเจ้าของใบรับรอง (CN)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="juristicName"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.juristicName}
                  />
                  <LabelModel>
                    ชื่อแผนกของเจ้าหน้าที่นิติบุคคลที่ระบุ (ถ้ามี) (OU)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อแผนก"
                    name="subdivision"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.subdivision}
                  />
                  <LabelModel>ชื่อนิติบุคคลเจ้าของใบรับรอง (O)</LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อนิติบุคคลเจ้าของใบรับรอง"
                    name="functionaryName"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.functionaryName}
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="idCard"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.idCard}
                  />
                  <LabelModel>
                    เลขประจำตัวประชาชนของเจ้าหน้าที่นิติบุคคลที่ระบุ (UID)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.juristicUID}
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.province}
                  />
                </YearContainer>
              ) : null}
              <label className="container">
                แบบที่ 4 นิติบุคคล + ตำแหน่ง
                <input
                  type="checkbox"
                  checked={props.isjuristicAndRank}
                  onChange={() =>
                    props.setjuristicAndRank(!props.isjuristicAndRank)
                  }
                  disabled={
                    props.isforIndividual === true ||
                    props.isjuristicPerson === true ||
                    props.isindividualAndJuristic === true
                  }
                />
                <span className="checkmark"></span>
              </label>
              {props.isjuristicAndRank ? (
                <YearContainer>
                  <LabelModel>
                    ชื่อตำแหน่งของเจ้าหน้าที่นิติบุคคลเจ้าของใบรับรอง (CN)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อตำแหน่งของเจ้าหน้าที่"
                    name="subdivision"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.subdivision}
                  />
                  <LabelModel>
                    ชื่อแผนกของเจ้าหน้าที่ นิติบุคคลที่ระบุ (ถ้ามี) (OU)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อแผนก"
                    name="juristicName"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.juristicName}
                  />
                  <LabelModel>ชื่อนิติบุคคลเจ้าของใบรับรอง (O)</LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อนิติบุคคล"
                    name="name"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.name}
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.juristicUID}
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.province}
                  />
                </YearContainer>
              ) : null}
            </SubProjectContainer>
          </YearContainer>
        ) : null}
      </ContactContainer>
    </div>
  )
}

export default FormService
