import React from "react"
import SelectOffice from "./SelectOffice"
import {
  InputColumn,
  Input,
  RowContainerInput,
  LabelModel,
  InputRow,
  RadioContainer,
  LabelSecond,
  SelectProfile,
  ContactContainer,
  SubProjectContainer,
  LabelHeader,
  BtnDownloadPDF,
  RowOffice,
  TypeContainer,
} from "./showElements"

const ShowRevoke = (props) => {
  return (
    <>
      <RadioContainer>
        <LabelSecond>ประเภทการสมัคร (Type): &nbsp;</LabelSecond>
        <LabelModel>{props.docs1.objective}</LabelModel>
      </RadioContainer>
      <LabelModel> ประเภทใบรับรองฯ ที่ขอใช้บริการ</LabelModel>
      <TypeContainer>
        <SelectProfile
          value={props.docs1.certificateType}
          multiple={false}
          disabled
        >
          <option
            key={props.docs1.certificateType}
            value={props.docs1.certificateType}
          >
            {props.docs1.certificateType}
          </option>
        </SelectProfile>
        <RowOffice>
          <LabelModel>สถานที่</LabelModel>
          <SelectOffice docs1={props.docs1} />
        </RowOffice>
      </TypeContainer>
      <LabelModel>หมายเลขใบรับรองฯ</LabelModel>
      <Input
        type="text"
        placeholder="หมายเลขใบรับรอง"
        name="certificateNo"
        onChange={props.handleChangeDocs1}
        value={props.docs1.certificateNo}
        disabled
      />
      <LabelModel>
        ชื่อ-นามสกุล เป็นภาษาไทย (First Name – Last Name in Thai)
      </LabelModel>
      <Input
        type="text"
        placeholder="ชื่อ-นามสกุล"
        name="fullNameTH"
        onChange={props.handleChangeDocs1}
        value={props.docs1.fullNameTH}
        disabled
      />
      <LabelModel>
        ชื่อ-นามสกุล เป็นภาษาอังกฤษ (First Name – Last Name in English)
      </LabelModel>
      <Input
        type="text"
        placeholder="First Name-Last Name"
        name="fullNameEN"
        onChange={props.handleChangeDocs1}
        value={props.docs1.fullNameEN}
        disabled
      />
      <LabelModel>เลขประจําตัวผู้เสียภาษีอากร</LabelModel>
      <Input
        type="text"
        placeholder="Tax No."
        name="taxNo"
        onChange={props.handleChangeDocs1}
        value={props.docs1.taxNo}
        disabled
      />
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วัน/เดือน/ปีเกิด (Date of Birth)</LabelModel>
          <InputRow
            type="input"
            placeholder="เช่น 01/01/2565"
            name="birthday"
            onChange={props.handleChangeDocs1}
            value={props.docs1.birthday}
            disabled
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วันออกบัตร</LabelModel>
          <InputRow
            type="input"
            placeholder="เช่น 01/01/2565"
            name="startDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.startDate}
            disabled
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>บัตรหมดอายุ</LabelModel>
          <InputRow
            type="input"
            placeholder="เช่น 01/01/2565"
            name="endDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.endDate}
            disabled
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>หนังสือเดินทางเลขที่</LabelModel>
          <InputRow
            type="input"
            placeholder="หนังสือเดินทางเลขที่"
            name="passport"
            onChange={props.handleChangeDocs1}
            value={props.docs1.passport}
            disabled
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>วันหมดอายุ</LabelModel>
          <InputRow
            type="input"
            placeholder="เช่น 01/01/2565"
            name="passportExpire"
            onChange={props.handleChangeDocs1}
            value={props.docs1.passportExpire}
            disabled
          />
        </InputColumn>
      </RowContainerInput>
      <LabelModel>ที่อยู่ตามทะเบียนบ้าน</LabelModel>
      <Input
        type="text"
        placeholder="ที่อยู่ตามทะเบียนบ้าน"
        name="houseRegistrationAddress"
        onChange={props.handleChangeDocs1}
        value={props.docs1.houseRegistrationAddress}
        disabled
      />
      <LabelModel>โทรศัพท์ที่บ้าน</LabelModel>
      <Input
        type="text"
        placeholder="โทรศัพท์ที่บ้าน"
        name="homePhone"
        onChange={props.handleChangeDocs1}
        value={props.docs1.homePhone}
        disabled
      />
      <LabelModel>โทรศัพท์มือถือ</LabelModel>
      <Input
        type="text"
        placeholder="โทรศัพท์มือถือ"
        name="mobilePhone"
        onChange={props.handleChangeDocs1}
        value={props.docs1.mobilePhone}
        disabled
      />
      <LabelModel>ตำแหน่งงาน</LabelModel>
      <Input
        type="text"
        placeholder="ตำแหน่งงาน"
        name="occupation"
        onChange={props.handleChangeDocs1}
        value={props.docs1.occupation}
        disabled
      />
      <LabelModel>ประเภทธุรกิจ</LabelModel>
      <Input
        type="text"
        placeholder="ประเภทธุรกิจ"
        name="businessType"
        onChange={props.handleChangeDocs1}
        value={props.docs1.businessType}
        disabled
      />
      <LabelModel>
        ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาไทย (Juristic Person/Organization Name in
        Thai)
      </LabelModel>
      <Input
        type="text"
        placeholder="ชื่อ-นามสกุล"
        name="juristicPersonOrOrganizationTH"
        onChange={props.handleChangeDocs1}
        value={props.docs1.juristicPersonOrOrganizationTH}
        disabled
      />
      <LabelModel>
        ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาอังกฤษ (Juristic Person/Organization Name
        in English)
      </LabelModel>
      <Input
        type="text"
        placeholder="First Name-Last Name"
        name="juristicPersonOrOrganizationEN"
        onChange={props.handleChangeDocs1}
        value={props.docs1.juristicPersonOrOrganizationEN}
        disabled
      />
      <LabelHeader>เอกสารประกอบคําร้องขอ</LabelHeader>
      <ContactContainer>
        <SubProjectContainer>
          <BtnDownloadPDF onClick={props.handleDownloadPDF}>
            Download PDF
          </BtnDownloadPDF>
          <BtnDownloadPDF onClick={props.handleDownloadFile}>
            Download File
          </BtnDownloadPDF>
        </SubProjectContainer>
      </ContactContainer>
    </>
  )
}

export default ShowRevoke
