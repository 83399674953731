import React, { useState } from "react"
import Navbar from "../components/Navbar/Navbar"
import Slidebar from "../components/Slidebar/Slidebar"
import Footer from "../components/Footer/Footer"
import Policy from "../components/Policy/Policy"

const PolicyPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div>
      <Slidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Policy />
      <Footer />
    </div>
  )
}

export default PolicyPage
