import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import authService from "../../../api/axios"
import useForm from "./useForm"
import ShowCertificate from "./ShowCertificate"
import ShowRevoke from "./ShowRevoke"
import ShowContact from "./ShowContact"
import ShowService from "./ShowService"
import ShowAdditional from "./ShowAdditional"
import Box from "@mui/material/Box"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import CircularProgress from "@mui/material/CircularProgress"
import CloseIcon from "@mui/icons-material/Close"

import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  BeforeFooter,
  InputHeader,
  ButtonContainer,
  BtnNext,
  LabelHeader,
  BtnPrev,
  ModelTextTitle,
} from "./showElements"

const step1 = "1. ชื่อและหลักฐานผู้ขอใช้บริการ (Customer Profile)"
const step2 =
  "2. ที่อยู่ในการติดต่อเพื่อส่งใบแจ้งค่าใช้บริการ และเอกสารอื่นๆ\n(Contact Address for sending billing/informing charge or other documents)"
const step3 = "3. รายละเอียดบริการ (Service Description)"
const step4 = "เอกสารประกอบ (Additional Documents)"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
}

const ShowDetails = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [, setCurrentUser] = useState(undefined)
  const FormTitle = [step1, step2, step3, step4]
  const {
    isLoading,
    docs1,
    open,
    docsAddress,
    isOtherAddress,
    sendTo,
    personalCertificate,
    webServerCertificate,
    Project,
    p3Details,
    p4Details,
    p6Details,
    p7Details,
    p7juristicPerson,
    p7individualAndJuristic,
    p7juristicAndRank,
    handleP7individualAndJuristic,
    handleP7juristicPerson,
    handleP7juristicAndRank,
    handleP4Details,
    setP6Details,
    setP3Details,
    handlePsc,
    handleWsc,
    handleChangeDocs1,
    handleChangeAddress,
    handleChangeOtherAddress,
    handleDownloadPDF,
    handleDownloadFile,
    handleCloseAround,
  } = useForm()

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/signin")
      window.location.reload()
    }
  }, [navigate])
  const PageDisplay = () => {
    if (docs1.objective === "สมัครใหม่" || docs1.objective === "ต่ออายุ") {
      if (page === 0) {
        return (
          <ShowCertificate
            docs1={docs1}
            handleChangeDocs1={handleChangeDocs1}
            docsAddress={docsAddress}
            handleChangeAddress={handleChangeAddress}
          />
        )
      } else if (page === 1) {
        return (
          <ShowContact
            isOtherAddress={isOtherAddress}
            handleChangeOtherAddress={handleChangeOtherAddress}
            sendTo={sendTo}
          />
        )
      } else if (page === 2) {
        return (
          <ShowService
            personalCertificate={personalCertificate}
            webServerCertificate={webServerCertificate}
            handlePsc={handlePsc}
            handleWsc={handleWsc}
            Project={Project}
            p3Details={p3Details}
            p4Details={p4Details}
            p6Details={p6Details}
            handleP4Details={handleP4Details}
            setP6Details={setP6Details}
            setP3Details={setP3Details}
            p7Details={p7Details}
            p7juristicPerson={p7juristicPerson}
            p7individualAndJuristic={p7individualAndJuristic}
            p7juristicAndRank={p7juristicAndRank}
            handleP7individualAndJuristic={handleP7individualAndJuristic}
            handleP7juristicPerson={handleP7juristicPerson}
            handleP7juristicAndRank={handleP7juristicAndRank}
          />
        )
      } else {
        return (
          <ShowAdditional
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadFile={handleDownloadFile}
          />
        )
      }
    } else {
      return (
        <ShowRevoke
          docs1={docs1}
          handleChangeDocs1={handleChangeDocs1}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadFile={handleDownloadFile}
        />
      )
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseAround}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ textAlign: "right" }}>
            <CloseIcon
              sx={{ fontSize: "30px", color: "gray" }}
              onClick={handleCloseAround}
            />
          </Typography>
          {isLoading === false ? (
            <>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "60px", color: "#ffd100" }}
                />
                <ModelTextTitle>ส่งคำขอดาวน์โหลดสำเร็จ</ModelTextTitle>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: "200", textAlign: "center" }}
              ></Typography>
            </>
          ) : (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              <CircularProgress sx={{ fontSize: "60px", color: "#ffd100" }} />
              <ModelTextTitle>กรุณารอสักครู่...</ModelTextTitle>
            </Typography>
          )}
        </Box>
      </Modal>
      <HeroContainer>
        <MainContainer>
          <InputHeader>
            <WelcomeText>รายละเอียด</WelcomeText>
          </InputHeader>
          <InputContainer>
            <LabelHeader>{FormTitle[page]}</LabelHeader>
            <div>{PageDisplay()}</div>
            {docs1.objective === "สมัครใหม่" ? (
              <ButtonContainer>
                <BtnPrev
                  disabled={page === 0}
                  onClick={() => {
                    setPage((currentPage) => currentPage - 1)
                  }}
                >
                  ย้อนกลับ
                </BtnPrev>
                <BtnNext
                  disabled={page === FormTitle.length - 1}
                  onClick={() => {
                    setPage((currentPage) => currentPage + 1)
                  }}
                >
                  ต่อไป
                </BtnNext>
              </ButtonContainer>
            ) : docs1.objective === "ต่ออายุ" ? (
              <ButtonContainer>
                <BtnPrev
                  disabled={page === 0}
                  onClick={() => {
                    setPage((currentPage) => currentPage - 1)
                  }}
                >
                  ย้อนกลับ
                </BtnPrev>
                <BtnNext
                  disabled={page === FormTitle.length - 1}
                  onClick={() => {
                    setPage((currentPage) => currentPage + 1)
                  }}
                >
                  ต่อไป
                </BtnNext>
              </ButtonContainer>
            ) : null}
          </InputContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </>
  )
}

export default ShowDetails
