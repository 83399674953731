export default function validatePage2(
  docs1,
  docsAddress,
  subdistrict,
  district,
  province,
  zipcode
) {
  let errors = {}

  if (!docs1.juristicPersonOrOrganizationTH.trim()) {
    errors.juristicPersonOrOrganizationTH =
      "กรุณากรอก ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาไทย"
  }
  if (!docs1.juristicPersonOrOrganizationEN.trim()) {
    errors.juristicPersonOrOrganizationEN =
      "กรุณากรอก ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาอังกฤษ"
  }
  if (!docs1.taxNo) {
    errors.taxNo = "กรุณากรอกเลขเสียภาษี"
  } else if (docs1.taxNo.length < 13 || docs1.taxNo.length > 13) {
    errors.taxNo = "กรุณากรอกเลขเสียภาษีให้ครบ 13 หลัก"
  }
  if (!docsAddress.houseNo.trim()) {
    errors.houseNo = "กรุณากรอกเลขที่อยู่"
  }
  if (!docsAddress.phoneNumber.trim()) {
    errors.phoneNumber = "กรุณากรอกเบอร์โทรศัพท์"
  }
  if (!docsAddress.nameCoordinator.trim()) {
    errors.nameCoordinator = "กรุณากรอก ชื่อ-สกุล ผู้ประสานงาน"
  }
  if (!docsAddress.email) {
    errors.email = "กรุณากรอกอีเมล"
  } else if (!/\S+@\S+\.\S+/.test(docsAddress.email)) {
    errors.email = "กรุณากรอกอีเมลให้ถูกต้อง"
  }
  if (!subdistrict.trim() || !district.trim()) {
    errors.subdistrict = "กรุณากรอกที่อยู่ แขวง/ตำบล และ เขต/อำเภอ"
  }
  if (!province.trim() || !zipcode.trim()) {
    errors.province = "กรุณากรอกที่อยู่ จังหวัด และ รหัสไปรษณีย์"
  }
  return errors
}
