export default function validatePage2(docs1) {
  let errors = {}

  if (!docs1.juristicPersonOrOrganizationTH.trim()) {
    errors.juristicPersonOrOrganizationTH =
      "กรุณากรอก ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาไทย"
  }
  if (!docs1.juristicPersonOrOrganizationEN.trim()) {
    errors.juristicPersonOrOrganizationEN =
      "กรุณากรอก ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาอังกฤษ"
  }
  if (!docs1.taxNo) {
    errors.taxNo = "กรุณากรอกเลขเสียภาษี"
  } else if (docs1.taxNo.length < 13 || docs1.taxNo.length > 13) {
    errors.taxNo = "กรุณากรอกเลขเสียภาษีให้ครบ 13 หลัก"
  }
  if (!docs1.email) {
    errors.email = "กรุณากรอกอีเมล"
  } else if (!/\S+@\S+\.\S+/.test(docs1.email)) {
    errors.email = "กรุณากรอกอีเมลให้ถูกต้อง"
  }
  return errors
}
