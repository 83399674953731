import React, { useState, useEffect } from "react"
import FormIndividual from "./FormIndividual"
import FormOther from "./FormOther"
import FormContact from "./FormContact"
import FormService from "./FormService"
import FormAdditional from "./FormAdditional"
import { useNavigate } from "react-router-dom"
import authService from "../../api/axios"
import useForm from "./useForm"
// import validate from "./varidateInfo"
import "./Styles.css"
import Box from "@mui/material/Box"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import SelectOffice from "./SelectOffice"
import CircularProgress from "@mui/material/CircularProgress"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  InputHeader,
  BeforeFooter,
  SelectProfile,
  BtnNext,
  LabelHeader,
  ButtonContainer,
  RadioCheck,
  TypeContainer,
  RadioContainer,
  LabelModel,
  BtnPrev,
  ModelTextTitle,
  ModelText,
  RowOffice,
} from "./submitElements"
import CloseIcon from "@mui/icons-material/Close"
import validatePage1 from "./varidatePage1"
import validatePage2 from "./varidatePage2"
import validatePage3 from "./varidatePage3"
import validatePage4 from "./varidatePage4"

const individual = "บุคคลธรรมดา"
const juristic_person = "นิติบุคคล"
const government = "ภาครัฐ"
const forWho = [individual, juristic_person, government]
const step1 = "1. ชื่อและหลักฐานผู้ขอใช้บริการ (Customer Profile)"
const step2 =
  "2. ที่อยู่ในการติดต่อเพื่อส่งใบแจ้งค่าใช้บริการ และเอกสารอื่นๆ\n(Contact Address for sending billing/informing charge or other documents)"
const step3 = "3. รายละเอียดบริการ (Service Description)"
const step4 = "เอกสารประกอบ (Additional Documents)"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
}

const Submit = () => {
  const navigate = useNavigate()
  const {
    isLoading,
    docs1,
    open,
    message,
    docsAddress,
    isHeadOffice,
    isBranch,
    isSendToEmail,
    isSendToAddress,
    isOther,
    isOtherAddress,
    personalCertificate,
    webServerCertificate,
    isPersonal,
    isWebServer,
    project2,
    project1,
    project7,
    project6,
    project5,
    project4,
    project3,
    p3Details,
    p4Details,
    p6Details,
    isforIndividual,
    p7forIndividual,
    p7juristicPerson,
    isjuristicPerson,
    isindividualAndJuristic,
    p7individualAndJuristic,
    isjuristicAndRank,
    p7juristicAndRank,
    handleCloseAround,
    handleClose,
    uploadFile1,
    uploadFile2,
    uploadFile3,
    uploadFile4,
    uploadFile5,
    uploadFile6,
    uploadFile7,
    handleP7juristicAndRank,
    setjuristicAndRank,
    handleP7individualAndJuristic,
    setjuristicPerson,
    setindividualAndJuristic,
    handleP7juristicPerson,
    handleP7forIndividual,
    setforIndividual,
    setP6Details,
    handleP4Details,
    setP3Details,
    setProject3,
    setProject4,
    setProject5,
    setProject6,
    setProject7,
    setProject1,
    setProject2,
    setWebServer,
    setPersonal,
    handleChangeOtherAddress,
    handleChangeDocs1,
    handleChangeAddress,
    setHeadOffice,
    setBranch,
    setsendToEmail,
    setsendToAddress,
    setOther,
    handleSubmit,
    handlePsc,
    handleWsc,
    setPersonalCertificate,
  } = useForm()
  const [, setCurrentUser] = useState(undefined)
  const [values, setValues] = useState("")
  const [page, setPage] = useState(0)
  const [errorsPage1, setErrorsPage1] = useState({})
  const [errorsPage2, setErrorsPage2] = useState({})
  const [isCheck, setCheck] = useState(individual)
  const FormTitle = [step1, step2, step3, step4]
  const [subdistrict, setSubDistrict] = useState("")
  const [district, setDistrict] = useState("")
  const [province, setProvince] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [Office, setOffice] = React.useState("")
  const [isSubmitting1, setIsSubmitting1] = useState(false)
  const [isSubmitting2, setIsSubmitting2] = useState(false)
  const handleChangeOffice = (event) => {
    setOffice(event.target.value)
  }

  const onSelect = (fulladdress) => {
    const { subdistrict, district, province, zipcode } = fulladdress
    setSubDistrict(subdistrict)
    setDistrict(district)
    setProvince(province)
    setZipcode(zipcode)
  }
  const [isType, setType] = useState("สมัครใหม่")
  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/signin")
      window.location.reload()
    }
  }, [navigate])

  const handleChangeType = (e) => {
    const {
      target: { value },
    } = e
    setValues(typeof value === "string" ? value.split(",") : value)
    setCheck(value)
    if (value === individual) {
      docs1.createType = "บุคคลธรรมดา"
    } else if (value === juristic_person) {
      docs1.createType = "นิติบุคคล"
    } else if (value === government) {
      docs1.createType = "ภาครัฐ"
    }
  }

  const goNext = () => {
    if (page === 0) {
      if (docs1.createType === individual) {
        setErrorsPage1(
          validatePage1(
            docs1,
            docsAddress,
            subdistrict,
            district,
            province,
            zipcode
          )
        )
      } else {
        setErrorsPage1(
          validatePage2(
            docs1,
            docsAddress,
            subdistrict,
            district,
            province,
            zipcode
          )
        )
      }
      setIsSubmitting1(true)
    } else if (page === 1) {
      setPage((currentPage) => currentPage + 1)
    } else if (page === 2) {
      if (isPersonal === true) {
        setErrorsPage2(validatePage3(personalCertificate))
        setIsSubmitting2(true)
      } else if (isWebServer === true) {
        setErrorsPage2(validatePage4(webServerCertificate))
        setIsSubmitting2(true)
      } else {
        setIsSubmitting2(false)
        alert("กรุณาเลือกประเภทใบของใบรับรอง")
      }
    }
  }

  useEffect(() => {
    if (Object.keys(errorsPage1).length === 0 && isSubmitting1) {
      setPage((currentPage) => currentPage + 1)
    }
  }, [errorsPage1, isSubmitting1])

  useEffect(() => {
    if (Object.keys(errorsPage2).length === 0 && isSubmitting2) {
      setPage((currentPage) => currentPage + 1)
    }
  }, [errorsPage2, isSubmitting2])

  const PageDisplay = () => {
    if (page === 0) {
      if (isCheck === individual) {
        return (
          <FormIndividual
            docs1={docs1}
            handleChangeDocs1={handleChangeDocs1}
            handleChangeAddress={handleChangeAddress}
            docsAddress={docsAddress}
            subdistrict={subdistrict}
            setSubDistrict={setSubDistrict}
            district={district}
            setDistrict={setDistrict}
            province={province}
            setProvince={setProvince}
            zipcode={zipcode}
            setZipcode={setZipcode}
            onSelect={onSelect}
            errorsPage1={errorsPage1}
            page={page}
            setPage={setPage}
          />
        )
      } else {
        return (
          <FormOther
            docs1={docs1}
            handleChangeDocs1={handleChangeDocs1}
            handleChangeAddress={handleChangeAddress}
            docsAddress={docsAddress}
            setHeadOffice={setHeadOffice}
            isHeadOffice={isHeadOffice}
            isBranch={isBranch}
            setBranch={setBranch}
            subdistrict={subdistrict}
            setSubDistrict={setSubDistrict}
            district={district}
            setDistrict={setDistrict}
            province={province}
            setProvince={setProvince}
            zipcode={zipcode}
            setZipcode={setZipcode}
            errorsPage1={errorsPage1}
            onSelect={onSelect}
          />
        )
      }
    } else if (page === 1) {
      return (
        <FormContact
          isOtherAddress={isOtherAddress}
          handleChangeOtherAddress={handleChangeOtherAddress}
          isSendToEmail={isSendToEmail}
          setsendToEmail={setsendToEmail}
          isSendToAddress={isSendToAddress}
          setsendToAddress={setsendToAddress}
          setOther={setOther}
          isOther={isOther}
        />
      )
    } else if (page === 2) {
      return (
        <FormService
          docs1={docs1}
          personalCertificate={personalCertificate}
          handlePsc={handlePsc}
          webServerCertificate={webServerCertificate}
          handleWsc={handleWsc}
          setPersonalCertificate={setPersonalCertificate}
          isPersonal={isPersonal}
          setPersonal={setPersonal}
          isWebServer={isWebServer}
          setWebServer={setWebServer}
          project1={project1}
          setProject1={setProject1}
          project2={project2}
          setProject2={setProject2}
          project3={project3}
          setProject3={setProject3}
          p3Details={p3Details}
          setP3Details={setP3Details}
          project4={project4}
          setProject4={setProject4}
          p4Details={p4Details}
          handleP4Details={handleP4Details}
          project5={project5}
          setProject5={setProject5}
          project6={project6}
          setProject6={setProject6}
          p6Details={p6Details}
          setP6Details={setP6Details}
          project7={project7}
          setProject7={setProject7}
          isforIndividual={isforIndividual}
          setforIndividual={setforIndividual}
          p7forIndividual={p7forIndividual}
          handleP7forIndividual={handleP7forIndividual}
          isjuristicPerson={isjuristicPerson}
          setjuristicPerson={setjuristicPerson}
          p7juristicPerson={p7juristicPerson}
          handleP7juristicPerson={handleP7juristicPerson}
          isindividualAndJuristic={isindividualAndJuristic}
          setindividualAndJuristic={setindividualAndJuristic}
          p7individualAndJuristic={p7individualAndJuristic}
          handleP7individualAndJuristic={handleP7individualAndJuristic}
          isjuristicAndRank={isjuristicAndRank}
          setjuristicAndRank={setjuristicAndRank}
          p7juristicAndRank={p7juristicAndRank}
          handleP7juristicAndRank={handleP7juristicAndRank}
          errorsPage2={errorsPage2}
        />
      )
    } else {
      return (
        <FormAdditional
          uploadFile1={uploadFile1}
          uploadFile2={uploadFile2}
          uploadFile3={uploadFile3}
          uploadFile4={uploadFile4}
          uploadFile5={uploadFile5}
          uploadFile6={uploadFile6}
          uploadFile7={uploadFile7}
          isCheck={isCheck}
          message={message}
        />
      )
    }
  }
  useEffect(() => {
    if (isWebServer === true) {
      docs1.certificateType = "ใบรับรองเว็บไซต์"
    }
    if (Office === "สุโขทัย : สวรรคโลก") {
      docs1.officeLocation = "Sukhothai:Sawankhalok"
    } else if (Office === "สระแก้ว : อรัญประเทศ") {
      docs1.officeLocation = "Sa Kaeo:Aranyaprathet"
    } else if (Office === "สงขลา : อำเภอเมือง") {
      docs1.officeLocation = "Songkhla:Mueang"
    } else if (Office === "สงขลา : หาดใหญ่") {
      docs1.officeLocation = "Songkhla:Hat Yai"
    } else if (Office === "นครศรีธรรมราช : อำเภอเมือง") {
      docs1.officeLocation = "Nakhon Si Thammarat:Mueang"
    } else if (Office === "ยะลา : อำเภอเมือง") {
      docs1.officeLocation = "Yala:Mueang"
    } else if (Office === "ชลบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Chon Buri:Mueang"
    } else if (Office === "ชลบุรี : แหลมฉบัง") {
      docs1.officeLocation = "Chon Buri:Laem Chabang"
    } else if (Office === "ประจวบคีรีขันธ์ : อำเภอเมือง") {
      docs1.officeLocation = "Prachuap Khiri Khan:Mueang"
    } else if (Office === "ประจวบคีรีขันธ์ : หัวหิน") {
      docs1.officeLocation = "Prachuap Khiri Khan:Hua Hin"
    } else if (Office === "สิงห์บุรี : อำเภอเมือง") {
      docs1.officeLocation = "Sing Buri:Mueang"
    } else if (Office === "ระยอง : อำเภอเมือง") {
      docs1.officeLocation = "Rayong:Mueang"
    } else if (Office === "ระยอง : ปลวกแดง") {
      docs1.officeLocation = "Rayong :Pluak Daeng"
    } else if (Office === "ระยอง : มาบตาพุด") {
      docs1.officeLocation = "Rayong:Map Ta Phut"
    } else if (Office === "อุดรธานี : อำเภอเมือง") {
      docs1.officeLocation = "Udon Thani:Mueang"
    } else if (Office === "สุราษฎร์ธานี : อำเภอเมือง") {
      docs1.officeLocation = "Surat Thani:Mueang"
    } else if (Office === "พัทลุง : อำเภอเมือง") {
      docs1.officeLocation = "Phatthalung:Mueang"
    } else if (Office === "สุพรรณบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Suphan Buri:Mueang"
    } else if (Office === "ราชบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Ratchaburi:Mueang"
    } else if (Office === "สมุทรสาคร : อำเภอเมือง") {
      docs1.officeLocation = "Samut Sakhon:Mueang"
    } else if (Office === "สมุทรสาคร : กระทุ่มแบน") {
      docs1.officeLocation = "Samut Sakhon:Krathum Baen"
    } else if (Office === "สมุทรสงคราม : อำเภอเมือง") {
      docs1.officeLocation = "Samut Songkhram:Mueang"
    } else if (Office === "สมุทรปราการ : อำเภอเมือง") {
      docs1.officeLocation = "Samut Prakan:Mueang"
    } else if (Office === "มุกดาหาร : อำเภอเมือง") {
      docs1.officeLocation = "Mukdahan:Mueang"
    } else if (Office === "ชุมพร : อำเภอเมือง") {
      docs1.officeLocation = "Chumphon:Mueang"
    } else if (Office === "ภูเก็ต : อำเภอเมือง") {
      docs1.officeLocation = "Phuket:Mueang"
    } else if (Office === "บึงกาฬ : อำเภอเมือง") {
      docs1.officeLocation = "Bueng Kan:Mueang"
    } else if (Office === "หนองคาย : อำเภอเมือง") {
      docs1.officeLocation = "Nong Khai:Mueang"
    } else if (Office === "เชียงใหม่ : อำเภอเมือง") {
      docs1.officeLocation = "Chiang Mai:Mueang"
    } else if (Office === "ลำพูน : อำเภอเมือง") {
      docs1.officeLocation = "Lamphun:Mueang"
    } else if (Office === "อยุธยา : พระนครศรีอยุธยา") {
      docs1.officeLocation = "Ayutthaya:Ayutthaya"
    } else if (Office === "ฉะเชิงเทรา : อำเภอเมือง") {
      docs1.officeLocation = "Chachoengsao:Mueang"
    } else if (Office === "นครสวรรค์ : อำเภอเมือง") {
      docs1.officeLocation = "Nakhon Sawan:Mueang"
    } else if (Office === "บุรีรัมย์ : อำเภอเมือง") {
      docs1.officeLocation = "Buri Ram:Mueang"
    } else if (Office === "ปราจีนบุรี : กบินทร์บุรี") {
      docs1.officeLocation = "Prachin Buri:Kabin Buri"
    } else if (Office === "ขอนแก่น : อำเภอเมือง") {
      docs1.officeLocation = "Khon Kaen:Mueang"
    } else if (Office === "นครปฐม : อำเภอเมือง") {
      docs1.officeLocation = "Nakhon Pathom:Mueang"
    } else if (Office === "อุบลราชธานี : อำเภอเมือง") {
      docs1.officeLocation = "Ubon Ratchathani:Mueang"
    } else if (Office === "ลำปาง : อำเภอเมือง") {
      docs1.officeLocation = "Lampang:Mueang"
    } else if (Office === "ตาก : แม่สอด") {
      docs1.officeLocation = "Tak:Mae Sot"
    } else if (Office === "ระนอง : อำเภอเมือง") {
      docs1.officeLocation = "Ranong:Mueang"
    } else if (Office === "สระบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Saraburi:Mueang"
    } else if (Office === "นครราชสีมา : อำเภอเมือง") {
      docs1.officeLocation = "Nakhon Ratchasima:Mueang"
    } else if (Office === "กาญจนบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Kanchanaburi:Mueang"
    } else if (Office === "ปัตตานี : อำเภอเมือง") {
      docs1.officeLocation = "Pattani:Mueang"
    } else if (Office === "เชียงราย : อำเภอเมือง") {
      docs1.officeLocation = "Chiang Rai:Mueang"
    } else if (Office === "จันทบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Chanthaburi:Mueang"
    } else if (Office === "ปทุมธานี : คลองหลวง") {
      docs1.officeLocation = "Pathum Thani:Khlong Luang"
    } else {
      docs1.officeLocation = "HQ"
    }
    docs1.objective = isType
    docsAddress.subDistrict = subdistrict
    docsAddress.district = district
    docsAddress.province = province
    docsAddress.postalCode = zipcode
    console.log(docs1)
  }, [
    Office,
    district,
    docs1,
    docsAddress,
    isType,
    isWebServer,
    province,
    subdistrict,
    zipcode,
  ])
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseAround}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ textAlign: "right" }}>
            <CloseIcon
              sx={{ fontSize: "30px", color: "gray" }}
              onClick={handleCloseAround}
            />
          </Typography>
          {isLoading === false ? (
            <>
              {" "}
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "60px", color: "#ffd100" }}
                />
                <ModelTextTitle>ส่งคำขอสำเร็จ</ModelTextTitle>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: "200", textAlign: "center" }}
              >
                <ModelText>
                  การส่งคำขอเสร็จสมบูรณ์
                  <br />
                  กรุณาดาวน์โหลดเอกสาร เพื่อใช้แนบประกอบคำขอด้วย
                </ModelText>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: "200", textAlign: "center" }}
              >
                <BtnNext onClick={handleClose}>Download PDF</BtnNext>
              </Typography>
            </>
          ) : (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              <CircularProgress sx={{ fontSize: "60px", color: "#ffd100" }} />
              <ModelTextTitle>กรุณารอสักครู่...</ModelTextTitle>
            </Typography>
          )}
        </Box>
      </Modal>
      <HeroContainer>
        <MainContainer>
          <InputHeader>
            <WelcomeText>ยื่นคำขอ</WelcomeText>
            <RadioContainer>
              <LabelModel>ประเภทการสมัคร (Type): </LabelModel>
              <div>
                <RadioCheck
                  type="radio"
                  name="isType"
                  value="สมัครใหม่"
                  checked={isType === "สมัครใหม่"}
                  onChange={(e) => setType(e.target.value)}
                />
                <LabelModel>สมัครใหม่ (New)</LabelModel>
                <RadioCheck
                  type="radio"
                  name="isType"
                  value="ต่ออายุ"
                  checked={isType === "ต่ออายุ"}
                  onChange={(e) => setType(e.target.value)}
                />
                <LabelModel>ต่ออายุ (Renew)</LabelModel>
              </div>
            </RadioContainer>
          </InputHeader>
          <InputContainer>
            <TypeContainer>
              <SelectProfile
                value={values}
                onChange={handleChangeType}
                multiple={false}
                disabled={page !== 0}
              >
                {forWho.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </SelectProfile>
              <RowOffice>
                <LabelModel>สถานที่</LabelModel>
                <SelectOffice
                  Office={Office}
                  handleChangeOffice={handleChangeOffice}
                />
              </RowOffice>
            </TypeContainer>
            <LabelHeader>{FormTitle[page]}</LabelHeader>
            <div>{PageDisplay()}</div>
            <ButtonContainer>
              <BtnPrev
                disabled={page === 0}
                onClick={() => {
                  setPage((currentPage) => currentPage - 1)
                }}
              >
                ย้อนกลับ
              </BtnPrev>
              {page === 3 ? (
                <BtnNext onClick={handleSubmit}>ส่งคำขอ</BtnNext>
              ) : (
                <BtnNext
                  disabled={page === FormTitle.length - 1}
                  onClick={goNext}
                >
                  ต่อไป
                </BtnNext>
              )}
            </ButtonContainer>
          </InputContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter />
    </>
  )
}

export default Submit
