import React, { useState, useEffect } from "react"
import ShowTable from "./ShowTable"
import { useNavigate } from "react-router-dom"
import authService from "../../api/axios"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  BeforeFooter,
} from "./checkstatusElements"

const CheckStatus = () => {
  const navigate = useNavigate()
  const [, setCurrentUser] = useState(undefined)

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/signin")
    }
  }, [navigate])

  return (
    <div>
      <HeroContainer>
        <InputContainer>
          <WelcomeText>ตรวจสอบสถานะ</WelcomeText>
          <MainContainer>
            <ShowTable />
          </MainContainer>
        </InputContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </div>
  )
}

export default CheckStatus
