import React from "react"
import {
  LabelSecond,
  ContactContainer,
  Input,
  LabelModel,
  RadioYearContainer,
  YearContainer,
  SubProjectContainer,
} from "./showElements"
import "../../Submit/Styles.css"

const ShowService = (props) => {
  return (
    <div>
      <ContactContainer>
        <LabelSecond>ประเภทใบรับรองฯ ที่ขอใช้บริการ</LabelSecond>
        <LabelModel>
          Personal Certificate โปรดระบุอีเมล์ที่แสดงบนใบรับรอง (Specify E-mail
          which it show in the digital certificate)
        </LabelModel>
        <>
          <LabelModel>*ต้องระบุ E-mail</LabelModel>
          <Input
            type="text"
            placeholder="Email"
            name="email"
            onChange={props.handlePsc}
            value={props.personalCertificate.email}
            disabled
          />
          <YearContainer>
            <RadioYearContainer>
              <LabelModel>อายุการใช้บริการ : &nbsp;</LabelModel>
              <LabelModel>{props.personalCertificate.pyear} ปี</LabelModel>
            </RadioYearContainer>
          </YearContainer>
        </>
        <LabelModel>
          Web Server Certificate (สำหรับนำไปใช้งานกับเครื่อง Server)
          อายุการใช้บริการ (Valid time in year)
        </LabelModel>
        <>
          <YearContainer>
            <LabelSecond>
              โปรดระบุรายละเอียดในใบรับรอง เป็นภาษาอังกฤษ (Specify Certificate
              Detail)
            </LabelSecond>
            <LabelModel>Common Name (Domain Name) (CN)</LabelModel>
            <Input
              type="text"
              placeholder="Common Name"
              name="commonName"
              onChange={props.handleWsc}
              value={props.webServerCertificate.commonName}
              disabled
            />
            <LabelModel>Organization Unit (OU)</LabelModel>
            <Input
              type="text"
              placeholder="Organization Unit"
              name="organizationUnit"
              onChange={props.handleWsc}
              value={props.webServerCertificate.organizationUnit}
              disabled
            />
            <LabelModel>Organization (O)</LabelModel>
            <Input
              type="text"
              placeholder="Organization"
              name="organization"
              onChange={props.handleWsc}
              value={props.webServerCertificate.organization}
              disabled
            />
            <LabelModel>Locality (L) </LabelModel>
            <Input
              type="text"
              placeholder="Locality"
              name="locality"
              onChange={props.handleWsc}
              value={props.webServerCertificate.locality}
              disabled
            />
            <LabelModel>Country (C)</LabelModel>
            <Input
              type="text"
              placeholder="Country"
              name="country"
              onChange={props.handleWsc}
              value={props.webServerCertificate.country}
              disabled
            />
            <RadioYearContainer>
              <LabelModel>อายุการใช้บริการ : &nbsp;</LabelModel>
              <LabelModel>{props.webServerCertificate.wyear} ปี</LabelModel>
            </RadioYearContainer>
          </YearContainer>
        </>
        <LabelSecond>
          ประเภทโครงการที่นำใบรับรองฯ ไปใช้งาน (Specify for the specific project
          which use)
        </LabelSecond>
        <label className="container">
          โครงการ NSW เช่น กรมศุลกากร , กรมประมง , อย. , กระทรวงพลังงาน ,
          กรมป่าไม้ และ กรมการค้าต่างประเทศ เป็นต้น
          <input
            type="checkbox"
            checked={props.Project.project1}
            onChange={() => !props.Project.project1}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการ สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
          (คปภ.)
          <input
            type="checkbox"
            checked={props.Project.project2}
            onChange={() => !props.Project.project2}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการกลุ่มสมาคมธนาคารไทย
          <input
            type="checkbox"
            checked={props.Project.project3}
            onChange={() => !props.Project.project3}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        {props.Project.project3 === true ? (
          <YearContainer>
            <LabelModel>ระบุ ชื่อ-นามสกุล ผู้ใช้งาน เป็นภาษาอังกฤษ</LabelModel>
            <Input
              type="text"
              placeholder="Fisrt Name - Last Name"
              name="p3Details"
              onChange={(e) => props.setP3Details(e.target.value)}
              value={props.p3Details}
              disabled
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการสำนักงานพาณิชย์จังหวัด
          <input
            type="checkbox"
            checked={props.Project.project4}
            onChange={() => !props.Project.project4}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        {props.Project.project4 === true ? (
          <YearContainer>
            <LabelModel>ระบุ ชื่อ-นามสกุล ผู้ใช้งาน เป็นภาษาอังกฤษ</LabelModel>
            <Input
              type="text"
              placeholder="Fisrt Name - Last Name"
              name="name"
              onChange={props.handleP4Details}
              value={props.p4Details.name}
              disabled
            />
            <LabelModel>เลขที่บัตรประชาชนของผู้ใช้งาน</LabelModel>
            <Input
              type="text"
              placeholder="เลขที่บัตร"
              name="idCard"
              onChange={props.handleP4Details}
              value={props.p4Details.idCard}
              disabled
            />
            <LabelModel>บัตรหมดอายุ</LabelModel>
            <Input
              type="text"
              placeholder="บัตรหมดอายุ"
              name="expireDate"
              onChange={props.handleP4Details}
              value={props.p4Details.expireDate}
              disabled
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการสำนักงานป้องกันและปราบปรามการฟอกเงิน (ปปง.)
          <input
            type="checkbox"
            checked={props.Project.project5}
            onChange={() => !props.Project.project5}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการอื่นๆ
          <input
            type="checkbox"
            checked={props.Project.project6}
            onChange={() => !props.Project.project6}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        {props.Project.project6 === true ? (
          <YearContainer>
            <LabelModel>ระบุโครงการ</LabelModel>
            <Input
              type="text"
              placeholder="ระบุโครงการ"
              name="p6Details"
              onChange={(e) => props.setP6Details(e.target.value)}
              value={props.p6Details}
              disabled
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการ RDC Online ของ สวทช.
          <input
            type="checkbox"
            checked={props.Project.project7}
            onChange={() => !props.Project.project7}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        {props.Project.project7 === true ? (
          <YearContainer>
            <LabelSecond>
              โปรดเลือกรูปแบบที่นำไปใช้พร้อมระบุข้อมูลเป็นภาษาอังกฤษ
            </LabelSecond>
            <SubProjectContainer>
              <label className="container">
                แบบที่ 1 บุคคลธรรมดา
                <input
                  type="checkbox"
                  checked={props.p7Details.p7Details1}
                  onChange={() => !props.p7Details.p7Details1}
                  disabled
                />
                <span className="checkmark"></span>
              </label>
              <label className="container">
                แบบที่ 2 นิติบุคคล
                <input
                  type="checkbox"
                  checked={props.p7Details.p7Details2}
                  onChange={() => !props.p7Details.p7Details2}
                  disabled
                />
                <span className="checkmark"></span>
              </label>
              {props.p7Details.p7Details2 === true ? (
                <YearContainer>
                  <LabelModel>
                    ชื่อนิติบุคคลเจ้าของใบรับรอง (CN)และ (O)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="juristicName"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.juristicName}
                    disabled
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.juristicUID}
                    disabled
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.province}
                    disabled
                  />
                </YearContainer>
              ) : null}
              <label className="container">
                แบบที่ 3 นิติบุคคล + บุคคล
                <input
                  type="checkbox"
                  checked={props.p7Details.p7Details3}
                  onChange={() => !props.p7Details.p7Details3}
                  disabled
                />
                <span className="checkmark"></span>
              </label>
              {props.p7Details.p7Details3 === true ? (
                <YearContainer>
                  <LabelModel>E-mail เจ้าหน้าที่ของนิติบุคคล (E)</LabelModel>
                  <Input
                    type="text"
                    placeholder="E-mail"
                    name="functionaryEmail"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.functionaryEmail}
                    disabled
                  />
                  <LabelModel>
                    ชื่อเจ้าหน้าที่นิติบุคคลเจ้าของใบรับรอง (CN)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="juristicName"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.juristicName}
                    disabled
                  />
                  <LabelModel>
                    ชื่อแผนกของเจ้าหน้าที่นิติบุคคลที่ระบุ (ถ้ามี) (OU)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="subdivision"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.subdivision}
                    disabled
                  />
                  <LabelModel>ชื่อนิติบุคคลเจ้าของใบรับรอง (O)</LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อนิติบุคคลเจ้าของใบรับรอง"
                    name="functionaryName"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.functionaryName}
                    disabled
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="idCard"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.idCard}
                    disabled
                  />
                  <LabelModel>
                    เลขประจำตัวประชาชนของเจ้าหน้าที่นิติบุคคลที่ระบุ (UID)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.juristicUID}
                    disabled
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.province}
                    disabled
                  />
                </YearContainer>
              ) : null}
              <label className="container">
                แบบที่ 4 นิติบุคคล + ตำแหน่ง
                <input
                  type="checkbox"
                  checked={props.p7Details.p7Details4}
                  onChange={() => !props.p7Details.p7Details4}
                  disabled
                />
                <span className="checkmark"></span>
              </label>
              {props.p7Details.p7Details4 === true ? (
                <YearContainer>
                  <LabelModel>
                    ชื่อตำแหน่งของเจ้าหน้าที่นิติบุคคลเจ้าของใบรับรอง (CN)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="subdivision"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.subdivision}
                    disabled
                  />
                  <LabelModel>
                    ชื่อแผนกของเจ้าหน้าที่ นิติบุคคลที่ระบุ (ถ้ามี) (OU)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="juristicName"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.juristicName}
                    disabled
                  />
                  <LabelModel>ชื่อนิติบุคคลเจ้าของใบรับรอง (O)</LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="name"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.name}
                    disabled
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.juristicUID}
                    disabled
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.province}
                    disabled
                  />
                </YearContainer>
              ) : null}
            </SubProjectContainer>
          </YearContainer>
        ) : null}
      </ContactContainer>
    </div>
  )
}

export default ShowService
