import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import authService from "../../api/axios"
import FormAdditional from "./FormAdditional"
import FormJuristic from "./FormJuristic"
import FormIndividual from "./FormIndividual"
import Box from "@mui/material/Box"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import useForm from "./useForm"
import validatePage1 from "./varidatePage1"
import validatePage2 from "./varidatePage2"
import CircularProgress from "@mui/material/CircularProgress"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  ButtonContainer,
  BtnNext,
  BeforeFooter,
  RadioContainer,
  LabelModel,
  RadioCheck,
  InputHeader,
  LabelSecond,
  SelectProfile,
  TypeContainer,
  HeadTextContainer,
  LabelHeader,
  InputHeadSecond,
  BtnPrev,
  ModelTextTitle,
  ModelText,
  RowOffice,
} from "./revokeElements"
import CloseIcon from "@mui/icons-material/Close"
import SelectOffice from "./SelectOffice"

const individual = "บุคคลธรรมดา"
const juristic_person = "นิติบุคคล"
const government = "ภาครัฐ"
const forWho = [individual, juristic_person, government]
const step1 = "ข้อมูลของผู้ขอใช้ใบรับรองฯ"
const step2 =
  "เอกสารประกอบคําร้องขอ (โปรดยื่นเอกสารตัวจริงพร้อมสําเนาประกอบการสมัคร)"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
}

const Revoke = () => {
  const navigate = useNavigate()
  const [, setCurrentUser] = useState(undefined)
  const [errorsPage1, setErrorsPage1] = useState({})
  const [objective, setObjective] = useState("ยื่นคำขอยกเลิก")
  const [certificateType, setCertificateType] = useState("ใบรับรองบุคคล")
  const [values, setValues] = useState("")
  const [page, setPage] = useState(0)
  const [isCheck, setCheck] = useState(individual)
  const FormTitle = [step1, step2]
  const [Office, setOffice] = React.useState("")
  const [isSubmitting1, setIsSubmitting1] = useState(false)
  const handleChangeOffice = (event) => {
    setOffice(event.target.value)
  }
  const {
    isLoading,
    docs1,
    open,
    handleClose,
    handleChangeDocs1,
    handleSubmit,
    uploadFile1,
    uploadFile2,
    uploadFile3,
    uploadFile4,
    handleCloseAround,
  } = useForm()

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/signin")
      window.location.reload()
    }
  }, [navigate])

  const handleChangeType = (e) => {
    const {
      target: { value },
    } = e
    setValues(typeof value === "string" ? value.split(",") : value)
    setCheck(value)
    if (value === individual) {
      docs1.createType = "บุคคลธรรมดา"
    } else if (value === juristic_person) {
      docs1.createType = "นิติบุคคล"
    } else if (value === government) {
      docs1.createType = "ภาครัฐ"
    }
  }
  const goNext = () => {
    if (docs1.createType === individual) {
      setErrorsPage1(validatePage1(docs1))
    } else {
      setErrorsPage1(validatePage2(docs1))
    }
    setIsSubmitting1(true)
  }
  useEffect(() => {
    if (Object.keys(errorsPage1).length === 0 && isSubmitting1) {
      setPage((currentPage) => currentPage + 1)
    }
  }, [errorsPage1, isSubmitting1])

  const PageDisplay = () => {
    if (page === 0) {
      if (isCheck === individual) {
        return (
          <FormIndividual
            docs1={docs1}
            handleChangeDocs1={handleChangeDocs1}
            errorsPage1={errorsPage1}
          />
        )
      } else {
        return (
          <FormJuristic
            docs1={docs1}
            handleChangeDocs1={handleChangeDocs1}
            errorsPage1={errorsPage1}
          />
        )
      }
    } else {
      return (
        <FormAdditional
          isCheck={isCheck}
          uploadFile1={uploadFile1}
          uploadFile2={uploadFile2}
          uploadFile3={uploadFile3}
          uploadFile4={uploadFile4}
        />
      )
    }
  }
  useEffect(() => {
    docs1.objective = objective
    docs1.certificateType = certificateType
    if (Office === "สุโขทัย : สวรรคโลก") {
      docs1.officeLocation = "Sukhothai:Sawankhalok"
    } else if (Office === "สระแก้ว : อรัญประเทศ") {
      docs1.officeLocation = "Sa Kaeo:Aranyaprathet"
    } else if (Office === "สงขลา : อำเภอเมือง") {
      docs1.officeLocation = "Songkhla:Mueang"
    } else if (Office === "สงขลา : หาดใหญ่") {
      docs1.officeLocation = "Songkhla:Hat Yai"
    } else if (Office === "นครศรีธรรมราช : อำเภอเมือง") {
      docs1.officeLocation = "Nakhon Si Thammarat:Mueang"
    } else if (Office === "ยะลา : อำเภอเมือง") {
      docs1.officeLocation = "Yala:Mueang"
    } else if (Office === "ชลบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Chon Buri:Mueang"
    } else if (Office === "ชลบุรี : แหลมฉบัง") {
      docs1.officeLocation = "Chon Buri:Laem Chabang"
    } else if (Office === "ประจวบคีรีขันธ์ : อำเภอเมือง") {
      docs1.officeLocation = "Prachuap Khiri Khan:Mueang"
    } else if (Office === "ประจวบคีรีขันธ์ : หัวหิน") {
      docs1.officeLocation = "Prachuap Khiri Khan:Hua Hin"
    } else if (Office === "สิงห์บุรี : อำเภอเมือง") {
      docs1.officeLocation = "Sing Buri:Mueang"
    } else if (Office === "ระยอง : อำเภอเมือง") {
      docs1.officeLocation = "Rayong:Mueang"
    } else if (Office === "ระยอง : ปลวกแดง") {
      docs1.officeLocation = "Rayong :Pluak Daeng"
    } else if (Office === "ระยอง : มาบตาพุด") {
      docs1.officeLocation = "Rayong:Map Ta Phut"
    } else if (Office === "อุดรธานี : อำเภอเมือง") {
      docs1.officeLocation = "Udon Thani:Mueang"
    } else if (Office === "สุราษฎร์ธานี : อำเภอเมือง") {
      docs1.officeLocation = "Surat Thani:Mueang"
    } else if (Office === "พัทลุง : อำเภอเมือง") {
      docs1.officeLocation = "Phatthalung:Mueang"
    } else if (Office === "สุพรรณบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Suphan Buri:Mueang"
    } else if (Office === "ราชบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Ratchaburi:Mueang"
    } else if (Office === "สมุทรสาคร : อำเภอเมือง") {
      docs1.officeLocation = "Samut Sakhon:Mueang"
    } else if (Office === "สมุทรสาคร : กระทุ่มแบน") {
      docs1.officeLocation = "Samut Sakhon:Krathum Baen"
    } else if (Office === "สมุทรสงคราม : อำเภอเมือง") {
      docs1.officeLocation = "Samut Songkhram:Mueang"
    } else if (Office === "สมุทรปราการ : อำเภอเมือง") {
      docs1.officeLocation = "Samut Prakan:Mueang"
    } else if (Office === "มุกดาหาร : อำเภอเมือง") {
      docs1.officeLocation = "Mukdahan:Mueang"
    } else if (Office === "ชุมพร : อำเภอเมือง") {
      docs1.officeLocation = "Chumphon:Mueang"
    } else if (Office === "ภูเก็ต : อำเภอเมือง") {
      docs1.officeLocation = "Phuket:Mueang"
    } else if (Office === "บึงกาฬ : อำเภอเมือง") {
      docs1.officeLocation = "Bueng Kan:Mueang"
    } else if (Office === "หนองคาย : อำเภอเมือง") {
      docs1.officeLocation = "Nong Khai:Mueang"
    } else if (Office === "เชียงใหม่ : อำเภอเมือง") {
      docs1.officeLocation = "Chiang Mai:Mueang"
    } else if (Office === "ลำพูน : อำเภอเมือง") {
      docs1.officeLocation = "Lamphun:Mueang"
    } else if (Office === "อยุธยา : พระนครศรีอยุธยา") {
      docs1.officeLocation = "Ayutthaya:Ayutthaya"
    } else if (Office === "ฉะเชิงเทรา : อำเภอเมือง") {
      docs1.officeLocation = "Chachoengsao:Mueang"
    } else if (Office === "นครสวรรค์ : อำเภอเมือง") {
      docs1.officeLocation = "Nakhon Sawan:Mueang"
    } else if (Office === "บุรีรัมย์ : อำเภอเมือง") {
      docs1.officeLocation = "Buri Ram:Mueang"
    } else if (Office === "ปราจีนบุรี : กบินทร์บุรี") {
      docs1.officeLocation = "Prachin Buri:Kabin Buri"
    } else if (Office === "ขอนแก่น : อำเภอเมือง") {
      docs1.officeLocation = "Khon Kaen:Mueang"
    } else if (Office === "นครปฐม : อำเภอเมือง") {
      docs1.officeLocation = "Nakhon Pathom:Mueang"
    } else if (Office === "อุบลราชธานี : อำเภอเมือง") {
      docs1.officeLocation = "Ubon Ratchathani:Mueang"
    } else if (Office === "ลำปาง : อำเภอเมือง") {
      docs1.officeLocation = "Lampang:Mueang"
    } else if (Office === "ตาก : แม่สอด") {
      docs1.officeLocation = "Tak:Mae Sot"
    } else if (Office === "ระนอง : อำเภอเมือง") {
      docs1.officeLocation = "Ranong:Mueang"
    } else if (Office === "สระบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Saraburi:Mueang"
    } else if (Office === "นครราชสีมา : อำเภอเมือง") {
      docs1.officeLocation = "Nakhon Ratchasima:Mueang"
    } else if (Office === "กาญจนบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Kanchanaburi:Mueang"
    } else if (Office === "ปัตตานี : อำเภอเมือง") {
      docs1.officeLocation = "Pattani:Mueang"
    } else if (Office === "เชียงราย : อำเภอเมือง") {
      docs1.officeLocation = "Chiang Rai:Mueang"
    } else if (Office === "จันทบุรี : อำเภอเมือง") {
      docs1.officeLocation = "Chanthaburi:Mueang"
    } else if (Office === "ปทุมธานี : คลองหลวง") {
      docs1.officeLocation = "Pathum Thani:Khlong Luang"
    } else {
      docs1.officeLocation = "HQ"
    }
  }, [Office, certificateType, docs1, objective])
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseAround}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ textAlign: "right" }}>
            <CloseIcon
              sx={{ fontSize: "30px", color: "gray" }}
              onClick={handleCloseAround}
            />
          </Typography>
          {isLoading === false ? (
            <>
              {" "}
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "60px", color: "#ffd100" }}
                />
                <ModelTextTitle>ส่งคำขอสำเร็จ</ModelTextTitle>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: "200", textAlign: "center" }}
              >
                <ModelText>
                  การส่งคำขอเสร็จสมบูรณ์
                  <br />
                  กรุณาดาวน์โหลดเอกสาร เพื่อใช้แนบประกอบคำขอด้วย
                </ModelText>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: "200", textAlign: "center" }}
              >
                <BtnNext onClick={handleClose}>Download PDF</BtnNext>
              </Typography>
            </>
          ) : (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              <CircularProgress sx={{ fontSize: "60px", color: "#ffd100" }} />
              <ModelTextTitle>กรุณารอสักครู่...</ModelTextTitle>
            </Typography>
          )}
        </Box>
      </Modal>
      <HeroContainer>
        <MainContainer>
          <HeadTextContainer>
            <WelcomeText>เพิกถอน</WelcomeText>
          </HeadTextContainer>
          <InputHeader>
            <WelcomeText></WelcomeText>
            <RadioContainer>
              <LabelSecond>ประสงค์ให้ดำเนินการ :</LabelSecond>
              <div>
                <RadioCheck
                  type="radio"
                  name="objective"
                  value="ยื่นคำขอยกเลิก"
                  checked={objective === "ยื่นคำขอยกเลิก"}
                  onChange={(e) => setObjective(e.target.value)}
                />
                <LabelModel>เพิกถอนใบรับรอง</LabelModel>
              </div>
              <div>
                <RadioCheck
                  type="radio"
                  name="objective"
                  value="ระงับการใช้งาน"
                  checked={objective === "ระงับการใช้งาน"}
                  onChange={(e) => setObjective(e.target.value)}
                />
                <LabelModel>พักใช้ใบรับรอง</LabelModel>
              </div>
              <div>
                <RadioCheck
                  type="radio"
                  name="objective"
                  value="ยกเลิก"
                  checked={objective === "ยกเลิก"}
                  onChange={(e) => setObjective(e.target.value)}
                />
                <LabelModel>ยกเลิกการพักใช้ใบรับรอง</LabelModel>
              </div>
            </RadioContainer>
          </InputHeader>
          <InputHeadSecond>
            <WelcomeText></WelcomeText>
            <RadioContainer>
              <div>
                <LabelSecond>ประเภทของใบรับรอง :</LabelSecond>
                <RadioCheck
                  type="radio"
                  name="certificateType"
                  value="ใบรับรองบุคคล"
                  checked={certificateType === "ใบรับรองบุคคล"}
                  onChange={(e) => setCertificateType(e.target.value)}
                />
                <LabelModel>ใบรับรองบุคคล</LabelModel>
                <RadioCheck
                  type="radio"
                  name="certificateType"
                  value="ใบรับรองเว็บไซต์"
                  checked={certificateType === "ใบรับรองเว็บไซต์"}
                  onChange={(e) => setCertificateType(e.target.value)}
                />
                <LabelModel>ใบรับรองเว็บไซต์</LabelModel>
              </div>
            </RadioContainer>
          </InputHeadSecond>
          <InputContainer>
            <TypeContainer>
              <SelectProfile
                value={values}
                onChange={handleChangeType}
                multiple={false}
                disabled={page !== 0}
              >
                {forWho.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </SelectProfile>
              <RowOffice>
                <LabelModel>สถานที่</LabelModel>
                <SelectOffice
                  Office={Office}
                  handleChangeOffice={handleChangeOffice}
                />
              </RowOffice>
            </TypeContainer>
            <LabelHeader>{FormTitle[page]}</LabelHeader>
            <div>{PageDisplay()}</div>
            <ButtonContainer>
              <BtnPrev
                disabled={page === 0}
                onClick={() => {
                  setPage((currentPage) => currentPage - 1)
                }}
              >
                ย้อนกลับ
              </BtnPrev>
              {page === 1 ? (
                <BtnNext onClick={handleSubmit}>ส่งคำขอ</BtnNext>
              ) : (
                <BtnNext
                  disabled={page === FormTitle.length - 1}
                  onClick={goNext}
                >
                  ต่อไป
                </BtnNext>
              )}
            </ButtonContainer>
          </InputContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter />
    </>
  )
}

export default Revoke
