export default function validateInfo(
  values,
  subdistrict,
  district,
  province,
  zipcode
) {
  let errors = {}

  if (!values.firstName.trim()) {
    errors.firstName = "FirstName required"
  }
  if (!values.lastName.trim()) {
    errors.lastName = "LastName required"
  }
  if (!subdistrict.trim() || !district.trim()) {
    errors.subdistrict = "Subdistrict & district required"
  }
  if (!province.trim() || !zipcode.trim()) {
    errors.province = "Province & Zipcode required"
  }

  if (!values.email) {
    errors.email = "Email required"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid"
  }
  if (!values.password) {
    errors.password = "Password is required"
  } else if (values.password.length < 6) {
    errors.password = "Password needs to be 6 characters or more"
  }

  if (!values.password2) {
    errors.password2 = "Password is required"
  } else if (values.password2 !== values.password) {
    errors.password2 = "Passwords do not match"
  }
  return errors
}
