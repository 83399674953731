import React from "react"
import {
  InputColumn,
  Input,
  RowContainerInput,
  LabelModel,
  InputRow,
  ContactContainer,
  LabelSecond,
} from "./showElements"
import "../../Submit/Styles.css"

const ShowContact = (props) => {
  return (
    <>
      <ContactContainer>
        <label className="container">
          ทางอีเมล์ (E-mail)
          <input
            type="checkbox"
            checked={props.sendTo.sendToEmail}
            onChange={() => !props.sendTo.sendToEmail}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          ที่อยู่ตามทะเบียนบ้าน/นิติบุคคล (Same address as special in the
          Household Registration Certificate or Juristic Person Certificate)
          <input
            type="checkbox"
            checked={props.sendTo.sendToAddress}
            onChange={() => !props.sendTo.sendToAddress}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          ที่อยู่อื่น (Other mailing address)
          <input
            type="checkbox"
            checked={props.isOtherAddress.isCheck}
            disabled
          />
          <span className="checkmark"></span>
        </label>
        {props.isOtherAddress.isCheck === true ? (
          <div>
            <LabelSecond>ที่อยู่อื่น (Other mailing address)</LabelSecond>
            <RowContainerInput>
              <InputColumn>
                <LabelModel>เลขที่ (House No.)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="เลขที่"
                  name="houseNo"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.houseNo}
                  disabled
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>หมู่ที่ (Moo)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="หมู่ที่"
                  name="moo"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.moo}
                  disabled
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>อาคาร/หมู่บ้าน (Building)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="หมู่บ้าน "
                  name="building"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.building}
                  disabled
                />
              </InputColumn>
            </RowContainerInput>
            <RowContainerInput>
              <InputColumn>
                <LabelModel>ตรอก/ซอย (Soi)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="ตรอก/ซอย"
                  name="alley"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.alley}
                  disabled
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>ถนน (Street)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="ถนน"
                  name="street"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.street}
                  disabled
                />
              </InputColumn>
            </RowContainerInput>
            <RowContainerInput>
              <InputColumn>
                <LabelModel>แขวง (Sub District)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="แขวง"
                  name="subDistrict"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.subDistrict}
                  disabled
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>เขต/อำเภอ (District)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="เขต/อำเภอ"
                  name="district"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.district}
                  disabled
                />
              </InputColumn>
            </RowContainerInput>
            <LabelModel>จังหวัด (Province)</LabelModel>
            <Input
              type="text"
              placeholder="จังหวัด"
              name="province"
              onChange={props.handleChangeOtherAddress}
              value={props.isOtherAddress.province}
              disabled
            />
            <LabelModel>รหัสไปรษณีย์ (Postal Code)</LabelModel>
            <Input
              type="text"
              placeholder="รหัสไปรษณีย์"
              name="postCode"
              onChange={props.handleChangeOtherAddress}
              value={props.isOtherAddress.postCode}
              disabled
            />
            <RowContainerInput>
              <InputColumn>
                <LabelModel>หมายเลขติดต่อ (Mobile/Telephone)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="เบอร์โทร"
                  name="phoneNumber"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.phoneNumber}
                  disabled
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>โทรสาร (Fax)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="โทรสาร"
                  name="fax"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.fax}
                  disabled
                />
              </InputColumn>
            </RowContainerInput>
          </div>
        ) : null}
      </ContactContainer>
    </>
  )
}

export default ShowContact
