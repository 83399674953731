import React, { useState, useEffect } from "react"
import {
  InputFile,
  ContactContainer,
  LabelModel,
  SubProjectContainer,
  LabelSecond,
  ErrorsText,
} from "./submitElements"
import "./Styles.css"

const individual = "บุคคลธรรมดา"

const FormAdditional = (props) => {
  const [isIndividual, setIndividual] = useState(false)
  const [isJuristicPerson, setJuristicPerson] = useState(false)
  useEffect(() => {
    if (props.isCheck === individual) {
      setIndividual(true)
      console.log(isIndividual)
    } else {
      setJuristicPerson(true)
    }
  }, [isIndividual, props.isCheck])

  return (
    <ContactContainer>
      {isIndividual ? (
        <SubProjectContainer>
          <LabelSecond>กรณีบุคคลธรรมดา (In case of individual)</LabelSecond>
          <LabelModel>
            สำเนาบัตรประจำตัวประชาชนหรือเอกสารทางราชการที่มีรูปถ่ายของผู้ใช้บริการ
            <br />
            (Copy of Citizen ID Card or Government Document with photo)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile1(e)
            }}
          />
          <ErrorsText>{props.message.file1}</ErrorsText>
          <LabelModel>
            สำเนาทะเบียนบ้านของผู้ใช้บริการ (Copy of Household Registration
            Certificate)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile2(e)
            }}
          />
          <ErrorsText>{props.message.file2}</ErrorsText>
          <LabelModel>
            หนังสือมอบอำนาจและติดอากรแสตมป์ (กรณีมอบหมายบุคคลอื่นมาดำเนินการแทน)
            พร้อมแนบเอกสารเพิ่ม 2 รายการ <br />
            (Power of attorney letter with revenue stamp, attach with following
            additional documents)
          </LabelModel>
          <LabelModel>
            1. บัตรจริงพร้อมสำเนาบัตรประชาชนของผู้มอบอำนาจ (ID Card and Copy of
            Grantor Citizen ID Card or passport)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile3(e)
            }}
          />
          <LabelModel>
            2. สำเนาบัตรประจำตัวประชาชนของผู้รับมอบอำนาจ (Copy of Representative
            Citizen ID Card)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile4(e)
            }}
          />
        </SubProjectContainer>
      ) : null}
      {isJuristicPerson ? (
        <SubProjectContainer>
          <LabelSecond>
            กรณีนิติบุคคล หรือหน่วยงานภาครัฐ (In case of juristic person)
          </LabelSecond>
          <LabelModel>
            สำเนาทะเบียนพาณิชย์ของบริษัทที่จดทะเบียน หรือ
            สำเนาหนังสือรับรองการเป็นนิติบุคคล อายุไม่เกิน 6 เดือน (Juristic
            Certificate or Government Letter)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile2(e)
            }}
          />
          <LabelModel>
            สำเนาบัตรประจำตัวประชาชนหรือเอกสารทางราชการที่มีรูปถ่ายของผู้มีอำนาจกระทำการแทนนิติบุคคล/หน่วยงาน
            (Copy of Citizen ID Card or Government Document with photo)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile6(e)
            }}
          />
          <LabelModel>
            กรณีเป็นชาวต่างชาติให้แนบสำเนาใบสำคัญบุคคลต่างด้าว หรือ
            หนังสือเดินทาง หรือ ใบอนุญาตทำงาน (Copy Power of attorney letter
            with revenue stamp, attach with following additional documents)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile7(e)
            }}
          />
          <LabelModel>
            หนังสือมอบอำนาจและติดอากรแสตมป์ (กรณีมอบหมายบุคคลอื่นมาดำเนินการแทน)
            พร้อมแนบเอกสารเพิ่ม 2 รายการ (Power of attorney letter with revenue
            stamp, attach with following additional documents)
          </LabelModel>
          <LabelModel>
            1. สำเนาบัตรประจำตัวประชาชนของผู้มอบอำนาจ (Copy of Grantor Citizen
            ID Card or passport)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile3(e)
            }}
          />
          <LabelModel>
            2. สำเนาบัตรประจำตัวประชาชนของผู้รับมอบอำนาจ (Copy of Representative
            Citizen ID Card)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile4(e)
            }}
          />
          <LabelModel>
            สำเนาบัตรประจำตัวประชาชนของผู้ใช้งานใบรับรองฯ (Copy of Certificate
            User Citizen ID Card) กรณีใช้กับโครงการ สมาคมธนาคารไทย /
            สำนักงานพาณิชย์จังหวัด / RDC Online
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile1(e)
            }}
          />
        </SubProjectContainer>
      ) : null}
    </ContactContainer>
  )
}

export default FormAdditional
