import React, { useState } from "react"
import Navbar from "../components/Navbar/Navbar"
import Slidebar from "../components/Slidebar/Slidebar"
import Footer from "../components/Footer/Footer"
import Submit from "../components/Submit/Submit"

const SubmitPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div>
      <Slidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Submit />
      <Footer />
    </div>
  )
}

export default SubmitPage
