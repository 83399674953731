import React from "react"
import {
  InputColumn,
  Input,
  RowContainerInput,
  LabelModel,
  InputRow,
  ErrorsText,
} from "./revokeElements"

const FormJuristic = (props) => {
  return (
    <>
      <LabelModel>หมายเลขใบรับรองฯ</LabelModel>
      <Input
        type="text"
        placeholder="หมายเลขใบรับรอง"
        name="certificateNo"
        onChange={props.handleChangeDocs1}
        value={props.docs1.certificateNo}
      />
      <LabelModel>ชื่อนิติบุคคล (ภาษาไทย)</LabelModel>
      <Input
        type="text"
        placeholder="ชื่อ-นามสกุล"
        name="juristicPersonOrOrganizationTH"
        onChange={props.handleChangeDocs1}
        value={props.docs1.juristicPersonOrOrganizationTH}
      />
      {props.errorsPage1.juristicPersonOrOrganizationTH && (
        <ErrorsText>
          {props.errorsPage1.juristicPersonOrOrganizationTH}
        </ErrorsText>
      )}
      <LabelModel>ชื่อนิติบุคคล (ภาษาอังกฤษ)</LabelModel>
      <Input
        type="text"
        placeholder="First Name-Last Name"
        name="juristicPersonOrOrganizationEN"
        onChange={props.handleChangeDocs1}
        value={props.docs1.juristicPersonOrOrganizationEN}
      />
      {props.errorsPage1.juristicPersonOrOrganizationEN && (
        <ErrorsText>
          {props.errorsPage1.juristicPersonOrOrganizationEN}
        </ErrorsText>
      )}
      <LabelModel>เลขประจําตัวผู้เสียภาษีอากร</LabelModel>
      <Input
        type="text"
        placeholder="Tax No."
        name="taxNo"
        onChange={props.handleChangeDocs1}
        value={props.docs1.taxNo}
      />
      {props.errorsPage1.taxNo && (
        <ErrorsText>{props.errorsPage1.taxNo}</ErrorsText>
      )}
      <LabelModel>E-mail Address ที่ท่านสมัครใช้บริการใบรับรองฯ</LabelModel>
      <Input
        type="text"
        placeholder="อีเมล์"
        name="email"
        onChange={props.handleChangeDocs1}
        value={props.docs1.email}
      />
      {props.errorsPage1.email && (
        <ErrorsText>{props.errorsPage1.email}</ErrorsText>
      )}
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วัน/เดือน/ปีเกิด (Date of Birth)</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="birthday"
            onChange={props.handleChangeDocs1}
            value={props.docs1.birthday}
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วันออกบัตร</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="startDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.startDate}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>บัตรหมดอายุ</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="endDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.endDate}
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>หนังสือเดินทางเลขที่</LabelModel>
          <InputRow
            type="input"
            placeholder="หนังสือเดินทางเลขที่"
            name="passport"
            onChange={props.handleChangeDocs1}
            value={props.docs1.passport}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>วันหมดอายุ</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="passportExpire"
            onChange={props.handleChangeDocs1}
            value={props.docs1.passportExpire}
          />
        </InputColumn>
      </RowContainerInput>
    </>
  )
}

export default FormJuristic
