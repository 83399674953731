import React, { useState, useEffect } from "react"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"

const citizen = "citizen"
const registration = "registration"
const grantorCitizen = "grantorCitizen"
const representativeCitizen = "representativeCitizen"
const signature = "signature"
const juristicCertificate = "juristicCertificate"
const attorneyLetter = "attorneyLetter"

const useForm = () => {
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const [errors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isHeadOffice, setHeadOffice] = useState(false)
  const [isBranch, setBranch] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [baseFile1, setBaseFile1] = useState("")
  const [baseFile2, setBaseFile2] = useState("")
  const [baseFile3, setBaseFile3] = useState("")
  const [baseFile4, setBaseFile4] = useState("")
  const [baseFile5, setBaseFile5] = useState("")
  const [baseFile6, setBaseFile6] = useState("")
  const [baseFile7, setBaseFile7] = useState("")
  const [docsId, setDocsId] = useState("")
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleCloseAround = async () => {
    setOpen(false)
    navigate("/service")
  }
  const handleClose = async () => {
    console.log(docsId)
    await authService.getDownloadPDF(token, docsId)
    setOpen(false)
    navigate("/service")
  }
  const [docs1, setDocuments1] = useState({
    fullNameTH: "",
    fullNameEN: "",
    createType: "บุคคลธรรมดา",
    certificateType: "ใบรับรองบุลคล",
    objective: "สมัครใหม่",
    juristicPersonOrOrganizationTH: "",
    juristicPersonOrOrganizationEN: "",
    taxNo: "",
    businessType: "",
    registrationDate: "",
    cardId: "",
    startDate: "",
    endDate: "",
    passport: "",
    passportExpire: "",
    occupation: "",
    birthday: "",
    officeLocation: "HQ",
  })
  const [docsAddress, setDocsAddress] = useState({
    headOffice: false,
    branch: false,
    branchNo: "",
    houseNo: "",
    moo: "",
    building: "",
    soi: "",
    street: "",
    subDistrict: "",
    district: "",
    province: "",
    postalCode: "",
    nameCoordinator: "",
    phoneNumber: "",
    email: "",
  })
  const [isPersonal, setPersonal] = useState(false)
  const [isOtherAddress, setOtherAddress] = useState({
    isCheck: false,
    postCode: "",
    building: "",
    street: "",
    moo: "",
    alley: "",
    subDistrict: "",
    district: "",
    province: "",
    phoneNumber: "",
    fax: "",
    houseNo: "",
  })
  const [isWebServer, setWebServer] = useState(false)
  const [personalCertificate, setPersonalCertificate] = useState({
    isCheck: false,
    email: "",
    pyear: "",
  })
  const [webServerCertificate, setWebServerCertificate] = useState({
    isCheck: false,
    commonName: "",
    organizationUnit: "",
    organization: "",
    locality: "",
    country: "",
    wyear: "",
  })
  const [isSendToEmail, setsendToEmail] = useState(false)
  const [isSendToAddress, setsendToAddress] = useState(false)
  const [isOther, setOther] = useState(false)

  const [project1, setProject1] = useState(false)
  const [project2, setProject2] = useState(false)
  const [project3, setProject3] = useState(false)
  const [p3Details, setP3Details] = useState("")
  const [project4, setProject4] = useState(false)
  const [p4Details, setP4Details] = useState({
    name: "",
    idCard: "",
    expireDate: "",
  })
  const [project5, setProject5] = useState(false)
  const [project6, setProject6] = useState(false)
  const [p6Details, setP6Details] = useState("")
  const [project7, setProject7] = useState(false)
  const [isforIndividual, setforIndividual] = useState(false)
  const [isjuristicPerson, setjuristicPerson] = useState(false)
  const [isindividualAndJuristic, setindividualAndJuristic] = useState(false)
  const [isjuristicAndRank, setjuristicAndRank] = useState(false)
  const [p7forIndividual, setp7forIndividual] = useState({
    email: "",
    certificate: "",
    idCard: "",
    province: "",
  })
  const [p7juristicPerson, setp7juristicPerson] = useState({
    province: "",
    juristicName: "",
    juristicUID: "",
  })
  const [p7individualAndJuristic, setp7individualAndJuristic] = useState({
    functionaryEmail: "",
    functionaryName: "",
    subdivision: "",
    juristicName: "",
    juristicUID: "",
    idCard: "",
    province: "",
  })
  const [p7juristicAndRank, setp7juristicAndRank] = useState({
    name: "",
    subdivision: "",
    juristicName: "",
    juristicUID: "",
    province: "",
  })
  const [message] = useState({
    file1: "",
    file2: "",
    file3: "",
    file4: "",
  })
  const handleChangeDocs1 = (e) => {
    const { name, value } = e.target
    setDocuments1({
      ...docs1,
      [name]: value,
    })
  }
  const handleChangeAddress = (e) => {
    const { name, value } = e.target
    setDocsAddress({
      ...docsAddress,
      [name]: value,
    })
  }
  const handleChangeOtherAddress = (e) => {
    const { name, value } = e.target
    setOtherAddress({
      ...isOtherAddress,
      [name]: value,
    })
  }
  const handleWsc = (e) => {
    const { name, value } = e.target
    setWebServerCertificate({
      ...webServerCertificate,
      [name]: value,
    })
  }
  const handlePsc = (e) => {
    const { name, value } = e.target
    setPersonalCertificate({
      ...personalCertificate,
      [name]: value,
    })
  }
  const handleP4Details = (e) => {
    const { name, value } = e.target
    setP4Details({
      ...p4Details,
      [name]: value,
    })
  }
  const handleP7forIndividual = (e) => {
    const { name, value } = e.target
    setp7forIndividual({
      ...p7forIndividual,
      [name]: value,
    })
  }
  const handleP7individualAndJuristic = (e) => {
    const { name, value } = e.target
    setp7individualAndJuristic({
      ...p7individualAndJuristic,
      [name]: value,
    })
  }
  const handleP7juristicPerson = (e) => {
    const { name, value } = e.target
    setp7juristicPerson({
      ...p7juristicPerson,
      [name]: value,
    })
  }
  const handleP7juristicAndRank = (e) => {
    const { name, value } = e.target
    setp7juristicAndRank({
      ...p7juristicAndRank,
      [name]: value,
    })
  }
  const uploadFile1 = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    // const isLt2M = file.size / 1024 / 1024 < 2
    // if (!isLt2M) {
    //   setMessage({
    //     file1: "ไฟล์ต้องมีขนาดไม่เกิน 2MB!",
    //   })
    // } else {
    //   setMessage({
    //     file1: "",
    //   })
    // }
    const base64 = await convertBase64(file)
    setBaseFile1(base64)
  }
  const uploadFile2 = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile2(base64)
  }
  const uploadFile3 = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile3(base64)
  }
  const uploadFile4 = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile4(base64)
  }
  const uploadFile5 = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile5(base64)
  }
  const uploadFile6 = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile6(base64)
  }
  const uploadFile7 = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile7(base64)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const handleSend = async () => {
    setLoading(true)
    handleOpen()
    try {
      const response = await authService
        .CreateDocs(
          token,
          docs1,
          docsAddress,
          isSendToEmail,
          isSendToAddress,
          isOther,
          isOtherAddress,
          isPersonal,
          isWebServer,
          personalCertificate,
          webServerCertificate,
          project1,
          project2,
          project3,
          p3Details,
          project4,
          p4Details,
          project5,
          project6,
          p6Details,
          project7,
          isforIndividual,
          isjuristicPerson,
          isindividualAndJuristic,
          isjuristicAndRank,
          p7forIndividual,
          p7juristicPerson,
          p7individualAndJuristic,
          p7juristicAndRank
        )
        .then(
          (res) => {
            if (res) {
              let docsID = res.data._id
              let Type = res.data.createType
              setDocsId(docsID)
              console.log(docsID)
              console.log(Type)
              const upFile = async () => {
                if (Type === "บุคคลธรรมดา") {
                  if (baseFile1 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      citizen,
                      baseFile1
                    )
                  }
                  if (baseFile2 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      registration,
                      baseFile2
                    )
                  }
                  if (baseFile3 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      grantorCitizen,
                      baseFile3
                    )
                  }
                  if (baseFile4 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      representativeCitizen,
                      baseFile4
                    )
                  }
                  if (baseFile5 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      signature,
                      baseFile5
                    )
                  }
                } else {
                  if (baseFile1 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      juristicCertificate,
                      baseFile1
                    )
                  }
                  if (baseFile2 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      citizen,
                      baseFile2
                    )
                  }

                  if (baseFile3 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      attorneyLetter,
                      baseFile3
                    )
                  }

                  if (baseFile4 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      grantorCitizen,
                      baseFile4
                    )
                  }

                  if (baseFile5 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      representativeCitizen,
                      baseFile5
                    )
                  }

                  if (baseFile6 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      citizen,
                      baseFile6
                    )
                  }

                  if (baseFile7 !== "") {
                    await authService.uploadFile(
                      token,
                      docsID,
                      signature,
                      baseFile7
                    )
                  }
                }
              }
              upFile().then(() => {
                setLoading(false)
              })
            }
          },
          (error) => {
            alert(error.response.data.error)
            console.log(error)
          }
        )
      console.log(response)
    } catch (err) {
      alert(err)
      console.log(err)
    }
  }
  const handleSubmit = (e) => {
    docsAddress.headOffice = isHeadOffice
    docsAddress.branch = isBranch
    isOtherAddress.isCheck = isOther
    personalCertificate.isCheck = isPersonal
    webServerCertificate.isCheck = isWebServer
    parseInt(personalCertificate.pyear)
    parseInt(webServerCertificate.wyear)
    e.preventDefault()
    setIsSubmitting(true)
    handleSend()
    console.log("Upload Successfully")
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (isSubmitting === true) {
      }
    }
  }, [errors, isSubmitting])
  return {
    isLoading,
    open,
    errors,
    message,
    docs1,
    docsAddress,
    isSendToEmail,
    isSendToAddress,
    isHeadOffice,
    isBranch,
    isOther,
    isOtherAddress,
    personalCertificate,
    webServerCertificate,
    isPersonal,
    isWebServer,
    project1,
    project2,
    project3,
    project4,
    project5,
    project6,
    project7,
    p3Details,
    p4Details,
    p6Details,
    isforIndividual,
    isjuristicPerson,
    isindividualAndJuristic,
    isjuristicAndRank,
    p7forIndividual,
    p7juristicPerson,
    p7individualAndJuristic,
    p7juristicAndRank,
    handleCloseAround,
    handleClose,
    uploadFile1,
    uploadFile2,
    uploadFile3,
    uploadFile4,
    uploadFile5,
    uploadFile6,
    uploadFile7,
    handleP7juristicAndRank,
    handleP7individualAndJuristic,
    handleP7juristicPerson,
    handleP7forIndividual,
    setjuristicAndRank,
    setindividualAndJuristic,
    setjuristicPerson,
    setforIndividual,
    setP6Details,
    handleP4Details,
    setP3Details,
    setProject7,
    setProject6,
    setProject5,
    setProject4,
    setProject3,
    setProject2,
    setProject1,
    setWebServer,
    setPersonal,
    handleWsc,
    handlePsc,
    handleSubmit,
    handleChangeDocs1,
    handleChangeAddress,
    setsendToEmail,
    setsendToAddress,
    setDocsAddress,
    setHeadOffice,
    setBranch,
    handleChangeOtherAddress,
    setOther,
    setOtherAddress,
    setPersonalCertificate,
  }
}

export default useForm
