import React, { useState, useEffect } from "react"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  Input,
  ButtonContainer,
  BtnLinkLogin,
  LinkRegister,
  GoRegister,
  ForgotPassword,
  RowContainer,
  BeforeFooter,
} from "./signInElements"

const SignIn = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [, setCurrentUser] = useState(undefined)

  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      const response = await authService.login(email, password).then(
        () => {
          navigate("/service")
          window.location.reload()
        },
        (error) => {
          alert(error.response.data.error)
          console.log(error.response.data.error)
        }
      )
      console.log(response)
    } catch (err) {
      alert(err)
      console.log(err)
    }
  }

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
      navigate("/service")
    } else {
      navigate("/signin")
    }
  }, [navigate])
  return (
    <div>
      <HeroContainer>
        <MainContainer>
          <WelcomeText>Sign in</WelcomeText>
          <InputContainer>
            <Input
              type="text"
              id="username"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              placeholder="Email"
            />
            <Input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              placeholder="Password"
            />
          </InputContainer>
          <ForgotPassword to="/forgotpassword">
            Forgot Password ?
          </ForgotPassword>
          <ButtonContainer>
            <BtnLinkLogin onClick={handleLogin}>Sign in</BtnLinkLogin>
          </ButtonContainer>
          <RowContainer>
            <GoRegister>Don’t have an account?</GoRegister>
            <LinkRegister to="/policy">Register</LinkRegister>
          </RowContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </div>
  )
}

export default SignIn
