import React, { useEffect, useState } from "react"
import "./Styles.css"
import { FaBars } from "react-icons/fa"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"
import {
  Nav,
  NavbarContainer,
  NavRow,
  NavbarLogo,
  NavItem,
  NavLinks,
  NavBtn,
  BtnLinkLogin,
  BtnLinkRegis,
  DropdrowBtn,
  MobileIcon,
  NavMenu,
} from "./navbarElements"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { styled } from "@mui/material/styles"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import LogoutIcon from "@mui/icons-material/Logout"

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const [currentUser, setCurrentUser] = useState(undefined)
  const [data, setDatas] = useState([])

  const changeNav = () => {
    if (window.scrollY >= 60) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    window.addEventListener("scroll", changeNav)
  }, [])

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      const token = localStorage.getItem("token")
      setCurrentUser(user)
      const fetchData = async () => {
        await authService.getUserData(token).then((response) => {
          setDatas(response.data)
        })
      }
      fetchData()
    }
  }, [])
  const navigate = useNavigate()
  const logOut = () => {
    authService.logout()
    navigate("/")
  }
  const Profile = () => {
    navigate("/profile")
  }
  const Service = () => {
    navigate("/service")
  }

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 80,
      color: "rgb(0, 0, 0, 0.23)",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 20,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  }))

  return (
    <div>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavRow>
            {currentUser ? (
              <>
                <NavbarLogo onClick={Service}>NT</NavbarLogo>
                <NavMenu>
                  <NavItem>
                    <NavLinks to="/submit">ยื่นคำขอ</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="/checkstatus">ตรวจสอบสถานะ</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="/revoke">เพิกถอน</NavLinks>
                  </NavItem>
                </NavMenu>
              </>
            ) : (
              <NavbarLogo onClick={logOut}>NT</NavbarLogo>
            )}
          </NavRow>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          {currentUser ? (
            <NavBtn>
              <DropdrowBtn
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {data.firstName}&nbsp;&nbsp;{data.lastName}
              </DropdrowBtn>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={Profile}
                  disableRipple
                  sx={{
                    color: "#696969",
                    fontWeight: 400,
                    fontFamily: "Kanit",
                  }}
                >
                  <AccountCircleIcon
                    sx={{
                      color: "#696969",
                    }}
                  />
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={logOut}
                  disableRipple
                  sx={{
                    color: "#696969",
                    fontWeight: 400,
                    fontFamily: "Kanit",
                  }}
                >
                  <LogoutIcon />
                  Logout
                </MenuItem>
              </StyledMenu>
            </NavBtn>
          ) : (
            <NavBtn>
              <BtnLinkLogin to="/signin">Log in</BtnLinkLogin>
              <BtnLinkRegis to="/policy">Register</BtnLinkRegis>
            </NavBtn>
          )}
        </NavbarContainer>
      </Nav>
    </div>
  )
}

export default Navbar
