import React from "react"
import InputAddress from "react-thailand-address-autocomplete"
import {
  LabelSecond,
  InputColumn,
  Input,
  RowContainerInput,
  RowInputAutoaddress,
  LabelModel,
  InputRow,
  RowContainerBranch,
  ErrorsText,
} from "./submitElements"

const FormOther = (props) => {
  return (
    <>
      <LabelModel>
        ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาไทย (Juristic Person/Organization Name in
        Thai)
      </LabelModel>
      <Input
        type="text"
        placeholder="ชื่อ"
        name="juristicPersonOrOrganizationTH"
        onChange={props.handleChangeDocs1}
        value={props.docs1.juristicPersonOrOrganizationTH}
      />
      {props.errorsPage1.juristicPersonOrOrganizationTH && (
        <ErrorsText>
          {props.errorsPage1.juristicPersonOrOrganizationTH}
        </ErrorsText>
      )}
      <LabelModel>
        ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาอังกฤษ (Juristic Person/Organization Name
        in English)
      </LabelModel>
      <Input
        type="text"
        placeholder="Name"
        name="juristicPersonOrOrganizationEN"
        onChange={props.handleChangeDocs1}
        value={props.docs1.juristicPersonOrOrganizationEN}
      />
      {props.errorsPage1.juristicPersonOrOrganizationEN && (
        <ErrorsText>
          {props.errorsPage1.juristicPersonOrOrganizationEN}
        </ErrorsText>
      )}
      <LabelModel>
        เลขเสียภาษีของนิติบุคคล (Certificate of Juristic Person Registration)
      </LabelModel>
      <Input
        type="text"
        placeholder="เลขเสียภาษี"
        name="taxNo"
        maxLength={13}
        onChange={props.handleChangeDocs1}
        value={props.docs1.taxNo}
      />
      {props.errorsPage1.taxNo && (
        <ErrorsText>{props.errorsPage1.taxNo}</ErrorsText>
      )}
      <LabelModel>ประเภทธุรกิจ (Business Type)</LabelModel>
      <Input
        type="text"
        placeholder="ธุรกิจ"
        name="businessType"
        onChange={props.handleChangeDocs1}
        value={props.docs1.businessType}
      />
      <LabelModel>วันที่จดทะเบียน (Registration Date)</LabelModel>
      <Input
        type="date"
        placeholder="Ex. 01/01/2565"
        name="registrationDate"
        onChange={props.handleChangeDocs1}
        value={props.docs1.registrationDate}
      />
      <LabelSecond>
        ที่อยู่ตามหนังสือรับรองการจดทะเบียนนิติบุคคล/หน่วยงาน (Address from
        certificate of Juristic Person or Address of Government)
      </LabelSecond>
      <RowContainerBranch>
        <RowContainerBranch>
          <label className="container">
            สำนักงานใหญ่ (Head Office)
            <input
              type="checkbox"
              onChange={() => props.setHeadOffice(!props.isHeadOffice)}
              disabled={props.isBranch === true}
            />
            <span className="checkmark"></span>
          </label>
        </RowContainerBranch>
        <RowContainerBranch>
          <label className="container">
            สาขา (Branch)
            <input
              type="checkbox"
              onChange={() => props.setBranch(!props.isBranch)}
              disabled={props.isHeadOffice === true}
            />
            <span className="checkmark"></span>
          </label>
        </RowContainerBranch>
      </RowContainerBranch>
      {props.isBranch ? (
        <div>
          <LabelModel>รหัสสาขา (Branch No.)</LabelModel>
          <Input
            type="text"
            placeholder="รหัสสาขา"
            name="branchNo"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.branchNo}
          />
        </div>
      ) : null}
      <RowContainerInput>
        <InputColumn>
          <LabelModel>เลขที่ (House No.)</LabelModel>
          <InputRow
            type="text"
            placeholder="เลขที่"
            name="houseNo"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.houseNo}
          />
          {props.errorsPage1.houseNo && (
            <ErrorsText>{props.errorsPage1.houseNo}</ErrorsText>
          )}
        </InputColumn>
        <InputColumn>
          <LabelModel>หมู่ที่ (Moo)</LabelModel>
          <InputRow
            type="text"
            placeholder="หมู่ที่"
            name="moo"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.moo}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>อาคาร/ชั้น/เลขห้อง (Building No.)</LabelModel>
          <InputRow
            type="text"
            placeholder="อาคาร/ชั้น/เลขห้อง"
            name="building"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.building}
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>ตรอก/ซอย (Soi)</LabelModel>
          <InputRow
            type="text"
            placeholder="ตรอก/ซอย"
            name="soi"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.soi}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>ถนน (Street)</LabelModel>
          <InputRow
            type="text"
            placeholder="ถนน"
            name="street"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.street}
          />
        </InputColumn>
      </RowContainerInput>
      <RowInputAutoaddress>
        <InputColumn>
          <LabelModel>แขวง (Sub District)</LabelModel>
          <InputAddress
            style={{
              width: "155%",
              height: "3rem",
              border: "1px solid #545859",
              outlineStyle: "none",
              borderRadius: "8px",
              color: "#3c354e",
            }}
            placeholder="แขวง / ตำบล"
            address="subdistrict"
            name="subdistrict"
            value={props.subdistrict}
            onChange={(e) => props.setSubDistrict(e.target.value)}
            onSelect={props.onSelect}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>เขต/อำเภอ (District)</LabelModel>
          <InputAddress
            style={{
              width: "155%",
              height: "3rem",
              border: "1px solid #545859",
              borderRadius: "8px",
              outlineStyle: "none",
              color: "#3c354e",
            }}
            placeholder="เขต / อำเภอ"
            address="district"
            name="district"
            value={props.district}
            onChange={(e) => props.setDistrict(e.target.value)}
            onSelect={props.onSelect}
          />
        </InputColumn>
      </RowInputAutoaddress>
      {props.errorsPage1.subdistrict && (
        <ErrorsText>{props.errorsPage1.subdistrict}</ErrorsText>
      )}
      <RowInputAutoaddress>
        <InputColumn>
          <LabelModel>จังหวัด (Province)</LabelModel>
          <InputAddress
            style={{
              width: "155%",
              height: "3rem",
              border: "1px solid #545859",
              borderRadius: "8px",
              outlineStyle: "none",
              color: "#3c354e",
            }}
            placeholder="จังหวัด"
            address="province"
            name="province"
            value={props.province}
            onChange={(e) => props.setProvince(e.target.value)}
            onSelect={props.onSelect}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>รหัสไปรษณีย์ (Postal Code)</LabelModel>
          <InputAddress
            style={{
              width: "155%",
              height: "3rem",
              border: "1px solid #545859",
              borderRadius: "8px",
              outlineStyle: "none",
              color: "#3c354e",
            }}
            placeholder="เลขไปรษณีย์"
            address="zipcode"
            name="zipcode"
            value={props.zipcode}
            onChange={(e) => props.setZipcode(e.target.value)}
            onSelect={props.onSelect}
          />
        </InputColumn>
      </RowInputAutoaddress>
      {props.errorsPage1.province && (
        <ErrorsText>{props.errorsPage1.province}</ErrorsText>
      )}
      <LabelModel>ชื่อ-สกุล ผู้ประสานงาน (Name Coordinator)</LabelModel>
      <Input
        type="text"
        placeholder="ผู้ประสานงาน"
        name="nameCoordinator"
        onChange={props.handleChangeAddress}
        value={props.docsAddress.nameCoordinator}
      />
      {props.errorsPage1.nameCoordinator && (
        <ErrorsText>{props.errorsPage1.nameCoordinator}</ErrorsText>
      )}
      <LabelModel>หมายเลขติดต่อ (Mobile/Telephone)</LabelModel>
      <Input
        type="text"
        placeholder="เบอร์ติดต่อ"
        name="phoneNumber"
        onChange={props.handleChangeAddress}
        value={props.docsAddress.phoneNumber}
      />
      {props.errorsPage1.phoneNumber && (
        <ErrorsText>{props.errorsPage1.phoneNumber}</ErrorsText>
      )}
      <LabelModel>อีเมล์ (E-mail)</LabelModel>
      <Input
        type="text"
        placeholder="อีเมล์"
        name="email"
        onChange={props.handleChangeAddress}
        value={props.docsAddress.email}
      />
      {props.errorsPage1.email && (
        <ErrorsText>{props.errorsPage1.email}</ErrorsText>
      )}
    </>
  )
}

export default FormOther
