import React, { useState, useEffect } from "react"
import InputAddress from "react-thailand-address-autocomplete"
import validate from "./varidateInfo"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  Input,
  ButtonContainer,
  BtnRegister,
  LinkRegister,
  GoRegister,
  RowContainer,
  BeforeFooter,
  RowAutoComplete,
  ErrorsText,
  InputAddressSub,
  RowAddress,
} from "./registerElements"

const Register = () => {
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
    postcode: "",
    buildingName: "",
    houseNo: "",
    street: "",
    district: "",
    subDistrict: "",
    province: "",
    moo: "",
    village: "",
    alley: "",
  })
  const [subdistrict, setSubDistrict] = useState("")
  const [district, setDistrict] = useState("")
  const [province, setProvince] = useState("")
  const [zipcode, setZipcode] = useState("")

  const onSelect = (fulladdress) => {
    const { subdistrict, district, province, zipcode } = fulladdress
    setSubDistrict(subdistrict)
    setDistrict(district)
    setProvince(province)
    setZipcode(zipcode)
  }
  const handleChange = (e) => {
    const { value, name } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }
  const handleSubmit = (e) => {
    values.subDistrict = subdistrict
    values.district = district
    values.province = province
    values.postcode = zipcode
    e.preventDefault()
    setErrors(validate(values, subdistrict, district, province, zipcode))
    setIsSubmitting(true)
    console.log(values)
  }
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (isSubmitting === true) {
        const Signup = async () => {
          try {
            const response = authService.signup(values).then(
              () => {
                alert("สมัครสมาชิกสำเร็จ")
                navigate("/service")
              },
              (error) => {
                alert(error.response.data.error)
                console.log(error.response.data.error)
              }
            )
            console.log(response)
          } catch (error) {
            console.log(error.data)
          }
        }
        Signup()
      }
    } else {
      console.log("err")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])
  return (
    <div>
      <HeroContainer>
        <MainContainer>
          <WelcomeText>สร้างบัญชีของท่าน</WelcomeText>
          <InputContainer>
            <Input
              type="text"
              placeholder="ชื่อ"
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
            />
            {errors.firstName && <ErrorsText>{errors.firstName}</ErrorsText>}
            <Input
              type="text"
              placeholder="นามสกุล"
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
            />
            {errors.lastName && <ErrorsText>{errors.lastName}</ErrorsText>}
            <RowAddress>
              <InputAddressSub
                type="text"
                placeholder="เลขที่บ้าน"
                name="houseNo"
                value={values.houseNo}
                onChange={handleChange}
              />
              <InputAddressSub
                type="text"
                placeholder="อาคาร ชั้น ห้อง"
                name="buildingName"
                value={values.buildingName}
                onChange={handleChange}
              />
              <InputAddressSub
                type="text"
                placeholder="ซอย"
                name="alley"
                value={values.alley}
                onChange={handleChange}
              />
            </RowAddress>
            <RowAddress>
              <InputAddressSub
                type="text"
                placeholder="ถนน"
                name="street"
                value={values.street}
                onChange={handleChange}
              />
              <InputAddressSub
                type="text"
                placeholder="หมู่บ้าน"
                name="village"
                value={values.village}
                onChange={handleChange}
              />
              <InputAddressSub
                type="text"
                placeholder="หมู่ที่"
                name="moo"
                value={values.moo}
                onChange={handleChange}
              />
            </RowAddress>
            <RowAutoComplete>
              <InputAddress
                style={{
                  width: "120%",
                  height: "3rem",
                  border: "1px solid #545859",
                  outlineStyle: "none",
                  borderRadius: "8px",
                  color: "#3c354e",
                }}
                placeholder="แขวง / ตำบล"
                address="subdistrict"
                name="subdistrict"
                value={subdistrict}
                onChange={(e) => setSubDistrict(e.target.value)}
                onSelect={onSelect}
              />
              <InputAddress
                style={{
                  width: "120%",
                  height: "3rem",
                  border: "1px solid #545859",
                  borderRadius: "8px",
                  outlineStyle: "none",
                  color: "#3c354e",
                }}
                placeholder="เขต / อำเภอ"
                address="district"
                name="district"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                onSelect={onSelect}
              />
            </RowAutoComplete>
            {errors.subdistrict && (
              <ErrorsText>{errors.subdistrict}</ErrorsText>
            )}
            <RowAutoComplete>
              <InputAddress
                style={{
                  width: "120%",
                  height: "3rem",
                  border: "1px solid #545859",
                  borderRadius: "8px",
                  outlineStyle: "none",
                  color: "#3c354e",
                }}
                placeholder="จังหวัด"
                address="province"
                name="province"
                value={province}
                onChange={(e) => setProvince(e.target.value)}
                onSelect={onSelect}
              />

              <InputAddress
                style={{
                  width: "120%",
                  height: "3rem",
                  border: "1px solid #545859",
                  borderRadius: "8px",
                  outlineStyle: "none",
                  color: "#3c354e",
                }}
                placeholder="เลขไปรษณีย์"
                address="zipcode"
                name="zipcode"
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
                onSelect={onSelect}
              />
            </RowAutoComplete>
            {errors.province && <ErrorsText>{errors.province}</ErrorsText>}
            <Input
              type="text"
              placeholder="อีเมล"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            {errors.email && <ErrorsText>{errors.email}</ErrorsText>}
            <Input
              type="password"
              placeholder="รหัสผ่าน"
              name="password"
              onChange={handleChange}
              value={values.password}
            />
            {errors.password && <ErrorsText>{errors.password}</ErrorsText>}
            <Input
              id="cf-password"
              type="password"
              placeholder="ยืนยัน รหัสผ่าน"
              name="password2"
              onChange={handleChange}
              value={values.password2}
            />
            {errors.password2 && <ErrorsText>{errors.password2}</ErrorsText>}
            <ButtonContainer>
              <BtnRegister type="submit" onClick={handleSubmit}>
                สมัครสมาชิก
              </BtnRegister>
            </ButtonContainer>
            <RowContainer>
              <GoRegister>มีบัญชีแล้ว ?</GoRegister>
              <LinkRegister to="/signin">เข้าสู่ระบบ</LinkRegister>
            </RowContainer>
          </InputContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter />
    </div>
  )
}

export default Register
