import React, { useState, useEffect } from "react"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"

const officialdocuments = "citizen"
const household = "registration"
const applicant = "powerOfattorney"
const workpermit = "signature"

const useForm = () => {
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const [errors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [baseFile1, setBaseFile1] = useState("")
  const [baseFile2, setBaseFile2] = useState("")
  const [baseFile3, setBaseFile3] = useState("")
  const [baseFile4, setBaseFile4] = useState("")
  const [docsId, setDocsId] = useState("")
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleCloseAround = async () => {
    setOpen(false)
    navigate("/service")
  }
  const handleClose = async () => {
    console.log(docsId)
    await authService.getDownloadPDF(token, docsId)
    setOpen(false)
    navigate("/service")
  }
  const [docs1, setDocuments1] = useState({
    fullNameTH: "",
    fullNameEN: "",
    createType: "บุคคลธรรมดา",
    certificateNo: "",
    certificateType: "ใบรับรองบุคคล",
    objective: "ยื่นคำขอยกเลิก",
    cardId: "",
    juristicPersonOrOrganizationTH: "",
    juristicPersonOrOrganizationEN: "",
    taxNo: "",
    email: "",
    birthday: "",
    startDate: "",
    endDate: "",
    passport: "",
    passportExpire: "",
    officeLocation: "HQ",
  })
  const handleChangeDocs1 = (e) => {
    const { name, value } = e.target
    setDocuments1({
      ...docs1,
      [name]: value,
    })
  }
  const uploadFile1 = async (e) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile1(base64)
  }
  const uploadFile2 = async (e) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile2(base64)
  }
  const uploadFile3 = async (e) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile3(base64)
  }
  const uploadFile4 = async (e) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    setBaseFile4(base64)
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(docs1)
    setIsSubmitting(true)
    handleSend()
  }

  const handleSend = async () => {
    setLoading(true)
    handleOpen()
    try {
      const response = await authService.revokeDocs(token, docs1).then(
        (res) => {
          let docsID = res.data._id
          console.log(docsID)
          setDocsId(docsID)
          const upFile = async () => {
            if (baseFile1 !== "") {
              await authService.uploadFile(
                token,
                docsID,
                officialdocuments,
                baseFile1
              )
            }
            if (baseFile2 !== "") {
              await authService.uploadFile(token, docsID, household, baseFile2)
            }
            if (baseFile3 !== "") {
              await authService.uploadFile(token, docsID, applicant, baseFile3)
            }
            if (baseFile4 !== "") {
              await authService.uploadFile(token, docsID, workpermit, baseFile4)
            }
          }
          upFile().then(() => {
            setLoading(false)
          })
        },

        (error) => {
          alert(error.response.data.error)
          console.log(error)
        }
      )
      console.log(response)
    } catch (e) {
      alert(e)
      console.log(e)
    }
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (isSubmitting === true) {
      }
    }
  }, [errors, isSubmitting])
  return {
    docs1,
    open,
    isLoading,
    handleClose,
    handleCloseAround,
    handleChangeDocs1,
    handleSubmit,
    uploadFile1,
    uploadFile2,
    uploadFile3,
    uploadFile4,
  }
}

export default useForm
