export default function validatePage1(docs1) {
  let errors = {}

  if (!docs1.fullNameTH.trim()) {
    errors.fullNameTH = "กรุณากรอก ชื่อ-สกุล ภาษาไทย"
  }
  if (!docs1.fullNameEN.trim()) {
    errors.fullNameEN = "กรุณากรอก ชื่อ-สกุล ภาษาอังกฤษ"
  }
  if (!docs1.cardId.trim()) {
    errors.cardId = "กรุณากรอกเลขบัตรประชาชน"
  } else if (docs1.cardId.length < 13 || docs1.cardId.length > 13) {
    errors.cardId = "กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก"
  } else if (!docs1.cardId.replace(/\D/g, "")) {
    errors.cardId = "กรุณากรอกตัวเลข"
  }
  if (!docs1.email) {
    errors.email = "กรุณากรอกอีเมล"
  } else if (!/\S+@\S+\.\S+/.test(docs1.email)) {
    errors.email = "กรุณากรอกอีเมลให้ถูกต้อง"
  }
  return errors
}
