import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  BtnLinkLogin,
  RowContainer,
  BeforeFooter,
  BtnLogin,
} from "./policyElements"
import "./Styles.css"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"

const Policy = () => {
  let navigate = useNavigate()
  const [isTogle, setIsTogle] = useState(true)

  const addEventToTag = (node) => {
    if (node) {
      node.addEventListener("scroll", handleScroll)
    }
  }
  const handleScroll = (event) => {
    var node = event.target
    const isBottom = node.scrollHeight - node.scrollTop <= 700
    if (isBottom) {
      setIsTogle(false)
    }
  }
  return (
    <>
      <HeroContainer>
        <MainContainer>
          <WelcomeText>Policy</WelcomeText>
          <InputContainer>
            <DialogContent ref={addEventToTag}>
              <DialogContentText
                color="#5E6164"
                fontFamily="Kanit"
                fontWeight="300"
              >
                {[...new Array(50)]
                  .map(
                    () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
                  )
                  .join("\n")}
              </DialogContentText>
            </DialogContent>
          </InputContainer>
          <RowContainer>
            <BtnLinkLogin to="/signin">ปฏิเสธ</BtnLinkLogin>
            <BtnLogin
              disabled={isTogle}
              onClick={() => {
                navigate("/register")
              }}
            >
              ตกลง
            </BtnLogin>
          </RowContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </>
  )
}

export default Policy
