export default function validatePage3(personalCertificate) {
  let errors = {}

  if (!personalCertificate.email.trim()) {
    errors.email = "กรุณากรอกอีเมล"
  }
  if (!personalCertificate.pyear.trim()) {
    errors.pyear = "กรุณาเลือกอายุการใช้บริการ"
  }
  return errors
}
