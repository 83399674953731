import React, { useState, useEffect } from "react"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"
import jwt_decode from "jwt-decode"
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SideBtnWrap,
  SidebarMenu,
  SidebarLink,
  BtnLinkLogin,
  BtnLinkRegis,
  SidebarBtn,
} from "./slideElements"

const Slidebar = ({ isOpen, toggle }) => {
  const [currentUser, setCurrentUser] = useState(undefined)
  const [userName, setName] = useState("")

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      const token = localStorage.getItem("token")
      setCurrentUser(user)
      var decoded = jwt_decode(token)
      setName(decoded.email)
    }
  }, [])
  const navigate = useNavigate()
  const logOut = () => {
    authService.logout()
    navigate("/")
  }
  return (
    <div>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          {currentUser ? (
            <div>
              <SideBtnWrap>{userName}</SideBtnWrap>
              <SidebarMenu>
                <SidebarLink to="/profile">ข้อมูลส่วนตัว</SidebarLink>
                <SidebarLink to="/submit">ยื่นคำขอ</SidebarLink>
                <SidebarLink to="/checkstatus">ตรวจสอบสถานะ</SidebarLink>
                <SidebarLink to="/revoke">เพิกถอน</SidebarLink>
                <SidebarBtn onClick={logOut}>Logout</SidebarBtn>
              </SidebarMenu>
            </div>
          ) : (
            <div>
              <SidebarMenu>
                <SidebarLink to="/service">Service</SidebarLink>
                <SidebarLink to="#">Pricing</SidebarLink>
                <SidebarLink to="#">Community</SidebarLink>
                <SidebarLink to="#">Support</SidebarLink>
              </SidebarMenu>
              <SideBtnWrap>
                <BtnLinkLogin to="/signin">Log in</BtnLinkLogin>
                <BtnLinkRegis to="/register">Register</BtnLinkRegis>
              </SideBtnWrap>
            </div>
          )}
        </SidebarWrapper>
      </SidebarContainer>
    </div>
  )
}

export default Slidebar
