export default function validatePage3(webServerCertificate) {
  let errors = {}

  if (!webServerCertificate.wyear.trim()) {
    errors.wyear = "กรุณาเลือกอายุการใช้บริการ"
  }
  if (!webServerCertificate.commonName.trim()) {
    errors.commonName = "กรุณากรอก Common Name"
  }
  if (!webServerCertificate.organizationUnit.trim()) {
    errors.organizationUnit = "กรุณากรอก Organization Unit"
  }
  if (!webServerCertificate.organization.trim()) {
    errors.organization = "กรุณากรอก Organization"
  }
  if (!webServerCertificate.locality.trim()) {
    errors.locality = "กรุณากรอก Locality"
  }
  return errors
}
