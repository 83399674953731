import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"
import beforeFooter from "../../images/beforeFooter.png"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  min-height: 80vh;
  justify-content: center;
`
export const MainContainer = styled.div`
  min-width: 60vw;
  min-height: 150px;
  background: #fff;
  box-shadow: 0 8px 32px 0 #d6d6d6;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 8px;
  color: #000;
`
export const RowContainer = styled.div`
  display: flex;
  margin-left: 10%;
`

export const WelcomeText = styled.h2`
  margin-top: 8rem;
  margin-bottom: 1rem;
  font-weight: 500;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const NodataContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`
export const StatusText1 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #008fbc;
`
export const StatusText2 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #00bc20;
`
export const StatusText3 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #db0d00;
`
export const StatusText4 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ffd100;
`
export const StatusText5 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #001ebc;
`
export const StatusText6 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ff7a00;
`
export const StatusText7 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #c000df;
`
export const StatusText8 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #757473;
`

export const LinkEdit = styled(LinkR)`
  font-size: 14px;
  font-weight: 400;
  color: #ffd100;
  text-decoration: none;
`
export const LinkDetail = styled(LinkR)`
  text-decoration: none;
  font-weight: 300;
`
export const LinkDelete = styled(LinkR)`
  font-size: 14px;
  font-weight: 400;
  color: #ff0000;
  text-decoration: none;
  margin-left: 6rem;
`
export const BtnSearch = styled.button`
  margin-left: 10px;
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 74px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const Input = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 40%;
  height: 40px;
  padding: 1rem;
  border: 1px solid #aaaaaa;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const RowContainerInput = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
export const RowContainerBranch = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
`
export const RowContainerSearch = styled.div`
  padding: 1rem;
  display: flex;
  width: 100%;
  justify-content: right;
`
export const InputColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
export const InputHeader = styled.div`
  padding-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ContactContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
`
export const TypeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  margin-bottom: 1rem;
`
export const YearContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
  margin-bottom: 1rem;
`
export const SubProjectContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
  margin-left: 1rem;
`

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const GoRegister = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #363a3d;
`
export const LinkRegister = styled(LinkR)`
  font-size: 14px;
  font-weight: 600;
  color: #ffd100;
  text-decoration: none;
  margin-left: 5px;
`
export const BtnLinkLogin = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const BtnNext = styled.button`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:disabled {
    cursor: default;
    background: #c6c4d4;
    color: #ffff;
    border: none;
  }
`
export const InputRow = styled.input`
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 8px;
  width: 80%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const InputAddressSub = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 30%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const CheckboxContact = styled.input`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #545859;
  margin-right: 4px;
  & input:checked {
    background-color: red;
  }
  &::before {
    background-color: #ffd100;
  }
`
export const RadioCheck = styled.input`
  margin-left: 1rem;
  margin-right: 5px;
  margin-top: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #545859;
`
export const RadioYear = styled.input`
  margin-right: 5px;
  margin-top: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #545859;
`
export const InputFile = styled.input`
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 8px;
  width: 40%;
  padding: 0.5rem;
  border: 1px solid #ffd100;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  ::-webkit-file-upload-button {
    display: none;
  }
  ::before {
    margin-right: 1rem;
    content: "เลือกไฟล์";
    display: inline-block;
    background: #ffd100;
    border: none;
    border-radius: 8px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
  }
`
export const RadioContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 1rem;
`
export const RadioYearContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: left;
`
export const SelectProfile = styled.select`
  border-radius: 8px;
  width: 40%;
  height: 3rem;
  padding-left: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
`
export const LabelModel = styled.label`
  color: #3c354e;
  font-size: 15px;
`
export const LabelHeader = styled.label`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  background: #ffe881;
`
export const LabelSecond = styled.label`
  margin-bottom: 1rem;
  color: #000;
  font-size: 15px;
  font-weight: 500;
`
export const ErrorsText = styled.p`
  color: red;
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
`
export const BeforeFooter = styled.div`
  background: #f8f9fa;
  background-image: url(${beforeFooter});
  max-width: 100%;
  height: 141px;
`
