import React from "react"
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksLeftWrapper,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkLeftItems,
  FooterLogo,
  FooterLinkTitle,
  FooterLink,
  BtnLinkLogin,
  BtnLinkRegis,
  FooterSocial,
  FooterLinksContainerSecond,
  FooterLinksContainerLast,
  HorizontalRule,
} from "./footerElements"

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksLeftWrapper>
            <FooterLinkLeftItems>
              <FooterLogo to="/">NT</FooterLogo>
              <span>
                Lorem Ipsum is simply dummy text of the printing and typesetting
              </span>
            </FooterLinkLeftItems>
          </FooterLinksLeftWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Features</FooterLinkTitle>
              <FooterLink to="/signin">Features</FooterLink>
              <FooterLink to="/signin">Features</FooterLink>
              <FooterLink to="/signin">Features</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Features</FooterLinkTitle>
              <FooterLink to="/signin">Features</FooterLink>
              <FooterLink to="/signin">Features</FooterLink>
              <FooterLink to="/signin">Features</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Features</FooterLinkTitle>
              <FooterLink to="/signin">Features</FooterLink>
              <FooterLink to="/signin">Features</FooterLink>
              <FooterLink to="/signin">Features</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <BtnLinkLogin to="/signin">Log in</BtnLinkLogin>
              <BtnLinkRegis to="/register">Register</BtnLinkRegis>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <FooterLinksContainerSecond>
          {/* <hr
            style={{
              height: 0.2,
            }}
          /> */}
          <HorizontalRule />
        </FooterLinksContainerSecond>
        <FooterLinksContainerLast>
          <span>© Logo, Inc. 2022. We love our users!</span>
          <FooterSocial>
            <span>Follow us:</span>
            <span>Social</span>
            <span>Social</span>
            <span>Social</span>
          </FooterSocial>
        </FooterLinksContainerLast>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
