import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"

export const FooterContainer = styled.footer`
  background-color: #545859;
`
export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
`
export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
  @media screen and (max-width: 640px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`
export const FooterLinksLeftWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`
export const FooterLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`
export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #ffd100;

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`
export const FooterLinkLeftItems = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  color: #ffd100;
  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`
export const FooterLogo = styled(LinkR)`
  color: #ffd100;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
`
export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`
export const FooterLink = styled(LinkR)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 100;

  &:hover {
    color: #ffd100;
    transition: 0.3s ease-out;
  }
`
export const BtnLinkLogin = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  margin: 0px 10px;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const BtnLinkRegis = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  margin: 15px 10px;
  color: #ffd100;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #ffd100;
  }
`
export const FooterLinksContainerSecond = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  max-width: 1350px;
`

export const FooterSocial = styled.div`
  display: flex;
`
export const FooterLinksContainerLast = styled.div`
  display: flex;
  color: #fff;
  font-size: 14px;
  font-weight: 100;
  justify-content: space-between;
  padding: 2% 5% 2% 5%;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
  @media screen and (max-width: 460px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`
export const HorizontalRule = styled.hr`
  width: 100%;
  height: 0.05rem;
  border: none;
  background: #fff;
  margin: 1.5rem 0 1rem 0;
  backdrop-filter: blur(25px);
`
