import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"
import beforeFooter from "../../images/beforeFooter.png"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  height: 90vh;
  justify-content: center;
`
export const MainContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 8px 32px 0 #d6d6d6;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 8px;
  color: #000;
  @media only screen and (max-width: 320px) {
    width: 80vw;
    height: 90vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 80vw;
    height: 100vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    height: 90vh;
  }
  @media only screen and (min-width: 768px) {
    width: 80vw;
    height: 90vh;
  }
  @media only screen and (min-width: 1024px) {
    width: 60vw;
    height: 90vh;
  }
  @media only screen and (min-width: 1280px) {
    width: 40vw;
    height: 80vh;
  }
`
export const RowContainer = styled.div`
  padding-top: 3rem;
  padding-left: 8rem;
  padding-right: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  @media only screen and (max-width: 680px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @media only screen and (max-width: 520px) {
    flex-direction: row;
    align-items: center;
  }
`

export const WelcomeText = styled.h2`
  margin-top: 4rem;
  margin-left: 10%;
  margin-bottom: 1rem;
  font-weight: bold;
`

export const InputContainer = styled.div`
  padding-left: 7%;
  padding-right: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
  width: 100%;
`
export const BtnLinkLogin = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
//  pointer-events: none;
export const BtnLinkRegis = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const BeforeFooter = styled.div`
  background: #f8f9fa;
  background-image: url(${beforeFooter});
  max-width: 100%;
  height: 141px;
`

export const BtnLogin = styled.button`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
  &:disabled {
    cursor: default;
    background: #c6c4d4;
    color: #ffff;
    border: none;
  }
`
