import React from "react"
import { SelectOfficeLocation } from "./showElements"

const SelectOffice = (props) => {
  const { docs1 } = props

  return (
    <SelectOfficeLocation
      multiple={false}
      value={docs1.officeLocation}
      disabled
    >
      <option>{docs1.officeLocation}</option>
    </SelectOfficeLocation>
  )
}

export default SelectOffice
