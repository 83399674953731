import styled from "styled-components"
import { FaTimes } from "react-icons/fa"
import { Link as LinkR } from "react-router-dom"
import { Link as LinkS } from "react-scroll"

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #fff;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`
export const CloseIcon = styled(FaTimes)`
  color: #363a3d;
`
export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

export const SidebarWrapper = styled.div`
  color: #ffd100;
`
export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 60px);
  text-align: center;
  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 40px);
  }
`
export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #363a3d;
  cursor: pointer;

  &:hover {
    color: #ffd100;
    transition: 0.2s ease-in-out;
  }
`
export const SidebarBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #363a3d;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;

  &:hover {
    color: #ffd100;
    transition: 0.2s ease-in-out;
  }
`
export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`
export const BtnLinkLogin = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  margin: 15px 10px;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const BtnLinkRegis = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  margin: 15px 10px;
  color: #ffd100;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #ffd100;
  }
`
