import React, { useEffect, useState } from "react"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"
import {
  HeroContainer,
  CardCircle,
  WelcomeText,
  Swipe,
  RowContainer,
  BeforeFooter,
} from "./serviceElements"

const Service = () => {
  const [, setCurrentUser] = useState(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/signin")
      window.location.reload()
    }
  }, [navigate])
  return (
    <>
      <HeroContainer>
        <RowContainer>
          <Swipe>
            <CardCircle to="/submit">
              <WelcomeText>ยื่นคำขอ</WelcomeText>
            </CardCircle>
          </Swipe>
          <Swipe>
            <CardCircle to="/checkstatus">
              <WelcomeText>ตรวจสอบสถานะ</WelcomeText>
            </CardCircle>
          </Swipe>
          <Swipe>
            <CardCircle to="/revoke">
              <WelcomeText>เพิกถอน</WelcomeText>
            </CardCircle>
          </Swipe>
        </RowContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </>
  )
}

export default Service
