import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"
import beforeFooter from "../../../images/beforeFooter.png"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  min-height: 120vh;
  justify-content: center;
`
export const MainContainer = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 8px 32px 0 #d6d6d6;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 8px;
  color: #000;
  @media only screen and (max-width: 320px) {
    width: 80vw;
    height: 90vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (max-width: 359px) {
    width: 80vw;
    min-height: 120vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 80vw;
    min-height: 120vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    min-height: 120vh;
  }
  @media only screen and (min-width: 768px) {
    width: 70vw;
    min-height: 120vh;
  }
  @media only screen and (min-width: 1024px) {
    width: 60vw;
    min-height: 100vh;
  }
  @media only screen and (min-width: 1280px) {
    width: 60vw;
    min-height: 100vh;
  }
`
export const RowContainer = styled.div`
  margin-left: 20%;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
`
export const RowContainerInput = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
export const RowContainerBranch = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
`

export const WelcomeText = styled.h2`
  font-weight: bold;
`

export const InputContainer = styled.div`
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
`
export const InputColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
export const InputHeader = styled.div`
  padding-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ContactContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
`
export const TypeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  margin-bottom: 1rem;
`
export const YearContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
  margin-bottom: 1rem;
`
export const SubProjectContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
  margin-left: 1rem;
`

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const GoRegister = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #363a3d;
`
export const LinkRegister = styled(LinkR)`
  font-size: 14px;
  font-weight: 600;
  color: #ffd100;
  text-decoration: none;
  margin-left: 5px;
`
export const BtnLinkLogin = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const BtnNext = styled.button`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:disabled {
    cursor: default;
    background: #c6c4d4;
    color: #ffff;
    border: none;
  }
`
export const BtnPrev = styled.button`
  border-radius: 8px;
  text-align: center;
  background: #c6c4d4;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:disabled {
    cursor: default;
    background: #c6c4d4;
    color: #ffff;
    border: none;
  }
`
export const Input = styled.input`
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const InputRow = styled.input`
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 8px;
  width: 80%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const InputAddressSub = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 30%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const CheckboxContact = styled.input`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #545859;
  margin-right: 4px;
  & input:checked {
    background-color: red;
  }
  &::before {
    background-color: #ffd100;
  }
`
export const RadioCheck = styled.input`
  margin-left: 1rem;
  margin-right: 5px;
  margin-top: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #545859;
`
export const RadioYear = styled.input`
  margin-right: 5px;
  margin-top: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #545859;
`
export const InputFile = styled.input`
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 8px;
  width: 40%;
  padding: 0.5rem;
  border: 1px solid #ffd100;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  ::-webkit-file-upload-button {
    display: none;
  }
  ::before {
    margin-right: 1rem;
    content: "เลือกไฟล์";
    display: inline-block;
    background: #ffd100;
    border: none;
    border-radius: 8px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
  }
`
export const RadioContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 1rem;
`
export const RadioYearContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: left;
`
export const BeforeFooter = styled.div`
  background: #f8f9fa;
  background-image: url(${beforeFooter});
  max-width: 100%;
  height: 141px;
`
export const SelectProfile = styled.select`
  border-radius: 8px;
  width: 40%;
  height: 3rem;
  padding-left: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
`
export const LabelModel = styled.label`
  color: #3c354e;
  font-size: 15px;
`
export const LabelHeader = styled.label`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  background: #ffe881;
`
export const LabelSecond = styled.label`
  color: #000;
  font-size: 15px;
  font-weight: 500;
`
export const ErrorsText = styled.p`
  color: red;
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
`
export const BtnDownloadPDF = styled.button`
  margin-top: 1rem;
  background: none;
  white-space: nowrap;
  color: #ffd100;
  font-size: 15px;
  outline: none;
  border: 1px solid #ffd100;
  border-radius: 8px;
  height: 3rem;
  cursor: pointer;
  text-decoration: none;
`
export const RowOffice = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right;
`
export const SelectOfficeLocation = styled.select`
  margin-left: 1rem;
  border-radius: 8px;
  width: 60%;
  height: 3rem;
  padding-left: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
`
export const ModelTextTitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #363a3d;
`
export const ModelText = styled.p`
  font-size: 15px;
  font-weight: 300;
  color: #363a3d;
`
