import React from "react"
import SelectOffice from "./SelectOffice"
import {
  RadioContainer,
  LabelModel,
  LabelSecond,
  Input,
  SelectProfile,
  RowContainerInput,
  InputColumn,
  InputRow,
  RowContainerBranch,
  RowOffice,
  TypeContainer,
} from "./showElements"
const isCheck = "บุคคลธรรมดา"

const ShowCertificate = (props) => {
  return (
    <>
      <RadioContainer>
        <LabelSecond>ประเภทการสมัคร (Type): &nbsp;</LabelSecond>
        <LabelModel>{props.docs1.objective}</LabelModel>
      </RadioContainer>
      <LabelModel> ประเภทใบรับรองฯ ที่ขอใช้บริการ</LabelModel>
      <TypeContainer>
        <SelectProfile value={props.docs1.createType} multiple={false} disabled>
          <option key={props.docs1.createType} value={props.docs1.createType}>
            {props.docs1.createType}
          </option>
        </SelectProfile>
        <RowOffice>
          <LabelModel>สถานที่</LabelModel>
          <SelectOffice docs1={props.docs1} />
        </RowOffice>
      </TypeContainer>
      {props.docs1.createType === isCheck ? (
        <div>
          <LabelModel>
            ชื่อ-นามสกุล เป็นภาษาไทย (First Name – Last Name in Thai)
          </LabelModel>
          <Input
            type="text"
            placeholder="ชื่อ-นามสกุล"
            name="fullNameTH"
            onChange={props.handleChangeDocs1}
            value={props.docs1.fullNameTH}
            disabled
          />
          <LabelModel>
            ชื่อ-นามสกุล เป็นภาษาอังกฤษ (First Name – Last Name in English)
          </LabelModel>
          <Input
            type="text"
            placeholder="First Name-Last Name"
            name="fullNameEN"
            onChange={props.handleChangeDocs1}
            value={props.docs1.fullNameEN}
            disabled
          />
          <LabelModel>เลขที่บัตรประชาชน (Citizen ID Card No)</LabelModel>
          <Input
            type="text"
            placeholder="เลขบัตรประชาชน"
            name="cardId"
            onChange={props.handleChangeDocs1}
            value={props.docs1.cardId}
            disabled
          />
          <LabelModel>อาชีพ (Occupation)</LabelModel>
          <Input
            type="text"
            placeholder="อาชีพ"
            name="occupation"
            onChange={props.handleChangeDocs1}
            value={props.docs1.occupation}
            disabled
          />
          <RowContainerInput>
            <InputColumn>
              <LabelModel>วัน/เดือน/ปีเกิด (Date of Birth)</LabelModel>
              <InputRow
                type="text"
                placeholder="เช่น 01/01/2565"
                name="birthday"
                onChange={props.handleChangeDocs1}
                value={props.docs1.birthday}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>บัตรหมดอายุ</LabelModel>
              <InputRow
                type="text"
                placeholder="เช่น 01/01/2565"
                name="endDate"
                onChange={props.handleChangeDocs1}
                value={props.docs1.endDate}
                disabled
              />
            </InputColumn>
          </RowContainerInput>
          <LabelSecond>
            ที่อยู่ตามทะเบียนบ้าน (Address as specified in the Household
            Registration Certificate)
          </LabelSecond>
          <RowContainerInput>
            <InputColumn>
              <LabelModel>เลขที่ (House No.)</LabelModel>
              <InputRow
                type="text"
                placeholder="เลขที่"
                name="houseNo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.houseNo}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>หมู่ที่ (Moo)</LabelModel>
              <InputRow
                type="text"
                placeholder="หมู่ที่"
                name="moo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.moo}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>อาคาร/หมู่บ้าน (Building No.)</LabelModel>
              <InputRow
                type="text"
                placeholder="หมู่บ้าน "
                name="building"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.building}
                disabled
              />
            </InputColumn>
          </RowContainerInput>
          <RowContainerInput>
            <InputColumn>
              <LabelModel>ตรอก/ซอย (Soi)</LabelModel>
              <InputRow
                type="text"
                placeholder="ตรอก/ซอย"
                name="soi"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.soi}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>ถนน (Street)</LabelModel>
              <InputRow
                type="text"
                placeholder="ถนน"
                name="street"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.street}
                disabled
              />
            </InputColumn>
          </RowContainerInput>
          <RowContainerInput>
            <InputColumn>
              <LabelModel>แขวง (Sub District)</LabelModel>
              <InputRow
                type="text"
                placeholder="แขวง"
                name="subDistrict"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.subDistrict}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>เขต/อำเภอ (District)</LabelModel>
              <InputRow
                type="text"
                placeholder="เขต/อำเภอ"
                name="district"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.district}
                disabled
              />
            </InputColumn>
          </RowContainerInput>
          <LabelModel>จังหวัด (Province)</LabelModel>
          <Input
            type="text"
            placeholder="จังหวัด"
            name="province"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.province}
            disabled
          />
          <LabelModel>รหัสไปรษณีย์ (Postal Code)</LabelModel>
          <Input
            type="text"
            placeholder="รหัสไปรษณีย์"
            name="postalCode"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.postalCode}
            disabled
          />
          <LabelModel>หมายเลขติดต่อ (Mobile/Telephone)</LabelModel>
          <Input
            type="text"
            placeholder="เบอร์โทร"
            name="phoneNumber"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.phoneNumber}
            disabled
          />
          <LabelModel>อีเมล์ (E-mail)</LabelModel>
          <Input
            type="text"
            placeholder="อีเมล์"
            name="email"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.email}
            disabled
          />
        </div>
      ) : (
        <div>
          <LabelModel>
            ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาไทย (Juristic Person/Organization
            Name in Thai)
          </LabelModel>
          <Input
            type="text"
            placeholder="ชื่อ-นามสกุล"
            name="juristicPersonOrOrganizationTH"
            onChange={props.handleChangeDocs1}
            value={props.docs1.juristicPersonOrOrganizationTH}
            disabled
          />
          <LabelModel>
            ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาอังกฤษ (Juristic Person/Organization
            Name in English)
          </LabelModel>
          <Input
            type="text"
            placeholder="First Name-Last Name"
            name="juristicPersonOrOrganizationEN"
            onChange={props.handleChangeDocs1}
            value={props.docs1.juristicPersonOrOrganizationEN}
            disabled
          />
          <LabelModel>
            เลขเสียภาษีของนิติบุคคล (Certificate of Juristic Person
            Registration)
          </LabelModel>
          <Input
            type="text"
            placeholder="เลขเสียภาษี"
            name="taxNo"
            onChange={props.handleChangeDocs1}
            value={props.docs1.taxNo}
            disabled
          />
          <LabelModel>ประเภทธุรกิจ (Business Type)</LabelModel>
          <Input
            type="text"
            placeholder="ธุรกิจ"
            name="businessType"
            onChange={props.handleChangeDocs1}
            value={props.docs1.businessType}
            disabled
          />
          <LabelModel>วันที่จดทะเบียน (Registration Date)</LabelModel>
          <Input
            type="text"
            placeholder="Ex. 01/01/2565"
            name="registrationDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.registrationDate}
            disabled
          />
          <LabelSecond>
            ที่อยู่ตามหนังสือรับรองการจดทะเบียนนิติบุคคล/หน่วยงาน (Address from
            certificate of Juristic Person or Address of Government)
          </LabelSecond>
          <RowContainerBranch>
            <RowContainerBranch>
              <label className="container">
                สำนักงานใหญ่ (Head Office)
                <input
                  type="checkbox"
                  checked={props.docsAddress.headOffice}
                  onChange={() => !props.docsAddress.headOffice}
                  disabled
                />
                <span className="checkmark"></span>
              </label>
            </RowContainerBranch>
            <RowContainerBranch>
              <label className="container">
                สาขา (Branch)
                <input
                  type="checkbox"
                  checked={props.docsAddress.branch}
                  onChange={() => !props.docsAddress.branch}
                  disabled
                />
                <span className="checkmark"></span>
              </label>
            </RowContainerBranch>
          </RowContainerBranch>
          {props.docsAddress.branch === true ? (
            <div>
              <LabelModel>รหัสสาขา (Branch No.)</LabelModel>
              <Input
                type="text"
                placeholder="รหัสสาขา"
                name="branchNo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.branchNo}
                disabled
              />
            </div>
          ) : null}
          <RowContainerInput>
            <InputColumn>
              <LabelModel>เลขที่ (House No.)</LabelModel>
              <InputRow
                type="text"
                placeholder="เลขที่"
                name="houseNo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.houseNo}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>หมู่ที่ (Moo)</LabelModel>
              <InputRow
                type="text"
                placeholder="หมู่ที่"
                name="moo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.moo}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>อาคาร/ชั้น/เลขห้อง (Building No.)</LabelModel>
              <InputRow
                type="text"
                placeholder="อาคาร/ชั้น/เลขห้อง"
                name="building"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.building}
                disabled
              />
            </InputColumn>
          </RowContainerInput>
          <RowContainerInput>
            <InputColumn>
              <LabelModel>ตรอก/ซอย (Soi)</LabelModel>
              <InputRow
                type="text"
                placeholder="ตรอก/ซอย"
                name="soi"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.soi}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>ถนน (Street)</LabelModel>
              <InputRow
                type="text"
                placeholder="ถนน"
                name="street"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.street}
                disabled
              />
            </InputColumn>
          </RowContainerInput>
          <RowContainerInput>
            <InputColumn>
              <LabelModel>แขวง (Sub District)</LabelModel>
              <InputRow
                type="text"
                placeholder="แขวง"
                name="subDistrict"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.subDistrict}
                disabled
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>เขต/อำเภอ (District)</LabelModel>
              <InputRow
                type="text"
                placeholder="เขต/อำเภอ"
                name="district"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.district}
                disabled
              />
            </InputColumn>
          </RowContainerInput>
          <LabelModel>จังหวัด (Province)</LabelModel>
          <Input
            type="text"
            placeholder="จังหวัด"
            name="province"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.province}
            disabled
          />
          <LabelModel>รหัสไปรษณีย์ (Postal Code)</LabelModel>
          <Input
            type="text"
            placeholder="รหัสไปรษณีย์"
            name="postalCode"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.postalCode}
            disabled
          />
          <LabelModel>ชื่อ-สกุล ผู้ประสานงาน (Name Coordinator)</LabelModel>
          <Input
            type="text"
            placeholder="ผู้ประสานงาน"
            name="nameCoordinator"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.nameCoordinator}
            disabled
          />
          <LabelModel>หมายเลขติดต่อ (Mobile/Telephone)</LabelModel>
          <Input
            type="text"
            placeholder="เบอร์ติดต่อ"
            name="phoneNumber"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.phoneNumber}
            disabled
          />
          <LabelModel>อีเมล์ (E-mail)</LabelModel>
          <Input
            type="text"
            placeholder="อีเมล์"
            name="email"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.email}
            disabled
          />
        </div>
      )}
      <div></div>
    </>
  )
}

export default ShowCertificate
