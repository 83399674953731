import React, { useState, useEffect } from "react"
import {
  InputFile,
  ContactContainer,
  LabelModel,
  SubProjectContainer,
  LabelSecond,
} from "./revokeElements"
const individual = "บุคคลธรรมดา"

const FormAdditional = (props) => {
  const [isIndividual, setIndividual] = useState(false)
  useEffect(() => {
    if (props.isCheck === individual) {
      setIndividual(true)
    }
  }, [isIndividual, props.isCheck])

  return (
    <ContactContainer>
      {isIndividual ? (
        <SubProjectContainer>
          <LabelSecond>กรณีขอใช้บริการในนามบุคคลธรรมดา</LabelSecond>
          <LabelModel>
            สําเนาบัตรประจําตัวประชาชน/เอกสารทางราชการที่มี
            รูปถ่ายของผู้ใช้บริการ
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile1(e)
            }}
          />
          <LabelModel>สําเนาทะเบียนบ้าน</LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile2(e)
            }}
          />
          <LabelModel>สําเนาบัตรประจําตัวประชาชนของผู้ยื่นคําขอฯ</LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile3(e)
            }}
          />
          <LabelModel>
            กรณีที่เป็นชาวต่างชาติให้แนบใบสําคัญบุคคลต่างด้าว หรือ
            หนังสือเดินทาง หรือใบอนุญาตทํางาน
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile4(e)
            }}
          />
        </SubProjectContainer>
      ) : (
        <SubProjectContainer>
          <LabelSecond>
            กรณีนิติบุคคล หรือหน่วยงานภาครัฐ (In case of juristic person)
          </LabelSecond>
          <LabelModel>
            สําเนาทะเบียนภาษีมูลค่าเพิ่มของบริษัท (ภ.พ.20)
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile1(e)
            }}
          />
          <LabelModel>
            สําเนาทะเบียนพาณิชย์ของบริษัทที่จดทะเบียน หรือ
            สําเนาหนังสือรับรองการเป็นนิติ บุคคล
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile2(e)
            }}
          />
          <LabelModel>
            กรณีมอบอํานาจให้แนบหนังสือมอบอํานาจที่ลงนามโดยผู้มอบอํานาจ
            และผู้รับมอบ อํานาจ
            พร้อมติดอากรแสตมป์และแนบสําเนาบัตรประจําตัวประชานของ ผู้มอบอำนาจ และ
            สําเนาบัตรประจําตัวประชานของผู้รับมอบอำนาจ
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile3(e)
            }}
          />
          <LabelModel>
            กรณีที่เป็นชาวต่างชาติให้แนบใบสําคัญบุคคลต่างด้าว หรือ
            หนังสือเดินทาง หรือ ใบอนุญาตทํางาน
          </LabelModel>
          <InputFile
            type="file"
            accept=".jeg,.jpeg,.png,application/pdf"
            onChange={(e) => {
              props.uploadFile4(e)
            }}
          />
        </SubProjectContainer>
      )}
    </ContactContainer>
  )
}

export default FormAdditional
