import React from "react"
import {
  InputColumn,
  Input,
  RowContainerInput,
  LabelModel,
  InputRow,
  ErrorsText,
} from "./revokeElements"

const FormIndividual = (props) => {
  return (
    <>
      <LabelModel>หมายเลขใบรับรองฯ</LabelModel>
      <Input
        type="text"
        placeholder="หมายเลขใบรับรอง"
        name="certificateNo"
        onChange={props.handleChangeDocs1}
        value={props.docs1.certificateNo}
      />
      <LabelModel>
        ชื่อ-นามสกุล เป็นภาษาไทย (First Name – Last Name in Thai)
      </LabelModel>
      <Input
        type="text"
        placeholder="ชื่อ-นามสกุล"
        name="fullNameTH"
        onChange={props.handleChangeDocs1}
        value={props.docs1.fullNameTH}
      />
      {props.errorsPage1.fullNameTH && (
        <ErrorsText>{props.errorsPage1.fullNameTH}</ErrorsText>
      )}
      <LabelModel>
        ชื่อ-นามสกุล เป็นภาษาอังกฤษ (First Name – Last Name in English)
      </LabelModel>
      <Input
        type="text"
        placeholder="First Name-Last Name"
        name="fullNameEN"
        onChange={props.handleChangeDocs1}
        value={props.docs1.fullNameEN}
      />
      {props.errorsPage1.fullNameEN && (
        <ErrorsText>{props.errorsPage1.fullNameEN}</ErrorsText>
      )}
      <LabelModel>บัตรประชาชน (Identification Card)</LabelModel>
      <Input
        type="text"
        placeholder="เลขบัตรประชาชน"
        name="cardId"
        maxLength={13}
        onChange={props.handleChangeDocs1}
        value={props.docs1.cardId}
      />
      {props.errorsPage1.cardId && (
        <ErrorsText>{props.errorsPage1.cardId}</ErrorsText>
      )}
      <LabelModel>E-mail Address ที่ท่านสมัครใช้บริการใบรับรองฯ</LabelModel>
      <Input
        type="text"
        placeholder="อีเมล์"
        name="email"
        onChange={props.handleChangeDocs1}
        value={props.docs1.email}
      />
      {props.errorsPage1.email && (
        <ErrorsText>{props.errorsPage1.email}</ErrorsText>
      )}
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วัน/เดือน/ปีเกิด (Date of Birth)</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="birthday"
            onChange={props.handleChangeDocs1}
            value={props.docs1.birthday}
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วันออกบัตร</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="startDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.startDate}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>บัตรหมดอายุ</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="endDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.endDate}
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>หนังสือเดินทางเลขที่</LabelModel>
          <InputRow
            type="input"
            placeholder="หนังสือเดินทางเลขที่"
            name="passport"
            onChange={props.handleChangeDocs1}
            value={props.docs1.passport}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>วันหมดอายุ</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="passportExpire"
            onChange={props.handleChangeDocs1}
            value={props.docs1.passportExpire}
          />
        </InputColumn>
      </RowContainerInput>
    </>
  )
}

export default FormIndividual
