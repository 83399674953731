import React from "react"
import {
  InputColumn,
  Input,
  RowContainerInput,
  LabelModel,
  InputRow,
  ContactContainer,
} from "./submitElements"
import "./Styles.css"

const FormContact = (props) => {
  return (
    <>
      <ContactContainer>
        <label className="container">
          ทางอีเมล์ (E-mail)
          <input
            type="checkbox"
            checked={props.isSendToEmail}
            onChange={() => props.setsendToEmail(!props.isSendToEmail)}
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          ที่อยู่ตามทะเบียนบ้าน/นิติบุคคล (Same address as special in the
          Household Registration Certificate or Juristic Person Certificate)
          <input
            type="checkbox"
            checked={props.isSendToAddress}
            onChange={() => props.setsendToAddress(!props.isSendToAddress)}
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          ที่อยู่อื่น (Other mailing address)
          <input
            type="checkbox"
            checked={props.isOther}
            onChange={() => props.setOther(!props.isOther)}
          />
          <span className="checkmark"></span>
        </label>
        {props.isOther ? (
          <div>
            <RowContainerInput>
              <InputColumn>
                <LabelModel>เลขที่ (House No.)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="เลขที่"
                  name="houseNo"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.houseNo}
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>หมู่ที่ (Moo)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="หมู่ที่"
                  name="moo"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.moo}
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>อาคาร/หมู่บ้าน (Building)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="หมู่บ้าน "
                  name="building"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.building}
                />
              </InputColumn>
            </RowContainerInput>
            <RowContainerInput>
              <InputColumn>
                <LabelModel>ตรอก/ซอย (Soi)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="ตรอก/ซอย"
                  name="alley"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.alley}
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>ถนน (Street)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="ถนน"
                  name="street"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.street}
                />
              </InputColumn>
            </RowContainerInput>
            <RowContainerInput>
              <InputColumn>
                <LabelModel>แขวง (Sub District)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="แขวง"
                  name="subDistrict"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.subDistrict}
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>เขต/อำเภอ (District)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="เขต/อำเภอ"
                  name="district"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.district}
                />
              </InputColumn>
            </RowContainerInput>
            <LabelModel>จังหวัด (Province)</LabelModel>
            <Input
              type="text"
              placeholder="จังหวัด"
              name="province"
              onChange={props.handleChangeOtherAddress}
              value={props.isOtherAddress.province}
            />
            <LabelModel>รหัสไปรษณีย์ (Postal Code)</LabelModel>
            <Input
              type="text"
              placeholder="รหัสไปรษณีย์"
              name="postCode"
              onChange={props.handleChangeOtherAddress}
              value={props.isOtherAddress.postCode}
            />
            <RowContainerInput>
              <InputColumn>
                <LabelModel>หมายเลขติดต่อ (Mobile/Telephone)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="เบอร์โทร"
                  name="phoneNumber"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.phoneNumber}
                />
              </InputColumn>
              <InputColumn>
                <LabelModel>โทรสาร (Fax)</LabelModel>
                <InputRow
                  type="text"
                  placeholder="โทรสาร"
                  name="fax"
                  onChange={props.handleChangeOtherAddress}
                  value={props.isOtherAddress.fax}
                />
              </InputColumn>
            </RowContainerInput>
          </div>
        ) : null}
      </ContactContainer>
    </>
  )
}

export default FormContact
