import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"
import beforeFooter from "../../images/beforeFooter.png"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  min-height: 135vh;
  justify-content: center;
`
export const MainContainer = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 8px 32px 0 #d6d6d6;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 8px;
  color: #000;
  @media only screen and (max-width: 320px) {
    width: 80vw;
    height: 90vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (max-width: 359px) {
    width: 80vw;
    height: 120vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 80vw;
    min-height: 120vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    min-height: 120vh;
  }
  @media only screen and (min-width: 768px) {
    width: 70vw;
    min-height: 120vh;
  }
  @media only screen and (min-width: 1024px) {
    width: 60vw;
    min-height: 120vh;
  }
  @media only screen and (min-width: 1280px) {
    width: 40vw;
    min-height: 120vh;
  }
`
export const RowContainer = styled.div`
  margin-left: 20%;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
`
export const RowContainerInput = styled.div`
  display: flex;
`
export const RowAutoComplete = styled.div`
  margin-top: 1rem;
  padding-right: 2.4rem;
  width: 80%;
  display: flex;
  justify-content: space-between;
`
export const RowAddress = styled.div`
  margin-top: 1rem;
  width: 80%;
  display: flex;
  justify-content: space-between;
`
export const WelcomeText = styled.h2`
  margin-top: 4rem;
  margin-left: 10%;
  margin-bottom: 1rem;
  font-weight: bold;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 20%;
  width: 100%;
  margin-bottom: 2rem;
`

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  margin-left: 20%;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
`

export const GoRegister = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #363a3d;
`
export const LinkRegister = styled(LinkR)`
  font-size: 14px;
  font-weight: 600;
  color: #ffd100;
  text-decoration: none;
  margin-left: 5px;
`

export const ForgotPassword = styled.h4`
  margin-top: 3rem;
  margin-right: 10%;
  text-align: right;
  cursor: pointer;
  font-weight: 300;
  color: #ffd100;
`
export const BtnRegister = styled.button`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const Input = styled.input`
  margin-top: 1rem;
  background: #fff;
  border-radius: 8px;
  width: 80%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: rgba(84, 88, 89, 0.8);
    font-weight: 400;
    font-size: 16px;
  }
`
export const InputAddressSub = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 30%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: rgba(84, 88, 89, 0.8);
    font-weight: 400;
    font-size: 16px;
  }
`
export const BeforeFooter = styled.div`
  background: #f8f9fa;
  background-image: url(${beforeFooter});
  max-width: 100%;
  height: 20vh;
`
export const ErrorsText = styled.p`
  margin-left: 22%;
  color: red;
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
`
