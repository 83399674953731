import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"
import beforeFooter from "../../images/beforeFooter.png"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  height: 80vh;
  justify-content: center;
`
export const RowContainer = styled.div`
  margin-top: 8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1279px) {
    width: 80%;
  }
  @media only screen and (max-width: 580px) {
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-top: 3rem;
  }
`
export const Swipe = styled.div`
  flex-shrink: 1;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 60%;
  &:hover {
    flex-basis: 70%;
  }
`

export const CardCircle = styled(LinkR)`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background: #ffd100;
  box-shadow: 0 8px 32px 0 #d6d6d6;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  @media only screen and (max-width: 1279px) {
    width: 200px;
    height: 200px;
  }
  @media only screen and (max-width: 760px) {
    width: 150px;
    height: 150px;
  }
  @media only screen and (max-width: 580px) {
    margin-top: 1rem;
  }
`
export const WelcomeText = styled.h2`
  font-weight: 500;
  text-align: center;
`
export const BeforeFooter = styled.div`
  background: #f8f9fa;
  background-image: url(${beforeFooter});
  max-width: 100%;
  height: 141px;
`
