import React from "react"
import { SelectOfficeLocation } from "./revokeElements"

const officeLocation = [
  "กรุงเทพฯ : ศูนย์บริการลูกค้าแจ้งวัฒนะ",
  "กรุงเทพฯ : แจ้งวัฒนะ บริการหน่วยงานเอกชน",
  "กรุงเทพฯ : ศูนย์บริการลูกค้าบางรัก (อาคาร 16)",
  "สุโขทัย : สวรรคโลก",
  "สระแก้ว : อรัญประเทศ",
  "สงขลา : อำเภอเมือง",
  "สงขลา : หาดใหญ่",
  "นครศรีธรรมราช : อำเภอเมือง",
  "ยะลา : อำเภอเมือง",
  "ชลบุรี : อำเภอเมือง",
  "ชลบุรี : แหลมฉบัง",
  "ประจวบคีรีขันธ์ : อำเภอเมือง",
  "ประจวบคีรีขันธ์ : หัวหิน",
  "สิงห์บุรี : อำเภอเมือง",
  "ระยอง : อำเภอเมือง",
  "ระยอง : ปลวกแดง",
  "ระยอง : มาบตาพุด",
  "อุดรธานี : อำเภอเมือง",
  "สุราษฎร์ธานี : อำเภอเมือง",
  "พัทลุง : อำเภอเมือง",
  "สุพรรณบุรี : อำเภอเมือง",
  "ราชบุรี : อำเภอเมือง",
  "สมุทรสาคร : อำเภอเมือง",
  "สมุทรสาคร : กระทุ่มแบน",
  "สมุทรสงคราม : อำเภอเมือง",
  "สมุทรปราการ : อำเภอเมือง",
  "มุกดาหาร : อำเภอเมือง",
  "ชุมพร : อำเภอเมือง",
  "ภูเก็ต : อำเภอเมือง",
  "บึงกาฬ : อำเภอเมือง",
  "หนองคาย : อำเภอเมือง",
  "เชียงใหม่ : อำเภอเมือง",
  "ลำพูน : อำเภอเมือง",
  "อยุธยา : พระนครศรีอยุธยา",
  "ฉะเชิงเทรา : อำเภอเมือง",
  "นครสวรรค์ : อำเภอเมือง",
  "บุรีรัมย์ : อำเภอเมือง",
  "ปราจีนบุรี : กบินทร์บุรี",
  "ขอนแก่น : อำเภอเมือง",
  "นครปฐม : อำเภอเมือง",
  "อุบลราชธานี : อำเภอเมือง",
  "ลำปาง : อำเภอเมือง",
  "ตาก : แม่สอด",
  "ระนอง : อำเภอเมือง",
  "สระบุรี : อำเภอเมือง",
  "นครราชสีมา : อำเภอเมือง",
  "กาญจนบุรี : อำเภอเมือง",
  "ปัตตานี : อำเภอเมือง",
  "เชียงราย : อำเภอเมือง",
  "จันทบุรี : อำเภอเมือง",
  "ปทุมธานี : คลองหลวง",
]

const SelectOffice = (props) => {
  const { Office, handleChangeOffice } = props

  return (
    <SelectOfficeLocation
      multiple={false}
      value={Office}
      onChange={handleChangeOffice}
    >
      {officeLocation.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </SelectOfficeLocation>
  )
}

export default SelectOffice
