import React from "react"
import InputAddress from "react-thailand-address-autocomplete"
import {
  LabelSecond,
  InputColumn,
  Input,
  RowContainerInput,
  RowInputAutoaddress,
  LabelModel,
  InputRow,
  ErrorsText,
} from "./submitElements"

const FormIndividual = (props) => {
  return (
    <>
      <LabelModel>
        ชื่อ-นามสกุล เป็นภาษาไทย (First Name – Last Name in Thai)
      </LabelModel>
      <Input
        type="text"
        placeholder="ชื่อ-นามสกุล"
        name="fullNameTH"
        onChange={props.handleChangeDocs1}
        value={props.docs1.fullNameTH}
      />
      {props.errorsPage1.fullNameTH && (
        <ErrorsText>{props.errorsPage1.fullNameTH}</ErrorsText>
      )}
      <LabelModel>
        ชื่อ-นามสกุล เป็นภาษาอังกฤษ (First Name – Last Name in English)
      </LabelModel>
      <Input
        type="text"
        placeholder="First Name-Last Name"
        name="fullNameEN"
        onChange={props.handleChangeDocs1}
        value={props.docs1.fullNameEN}
      />
      {props.errorsPage1.fullNameEN && (
        <ErrorsText>{props.errorsPage1.fullNameEN}</ErrorsText>
      )}
      <LabelModel>เลขที่บัตรประชาชน (Citizen ID Card No)</LabelModel>
      <Input
        type="text"
        placeholder="เลขบัตรประชาชน"
        name="cardId"
        maxLength={13}
        onChange={props.handleChangeDocs1}
        value={props.docs1.cardId}
      />
      {props.errorsPage1.cardId && (
        <ErrorsText>{props.errorsPage1.cardId}</ErrorsText>
      )}
      <LabelModel>อาชีพ (Occupation)</LabelModel>
      <Input
        type="text"
        placeholder="อาชีพ"
        name="occupation"
        onChange={props.handleChangeDocs1}
        value={props.docs1.occupation}
      />
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วัน/เดือน/ปีเกิด (Date of Birth)</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="birthday"
            onChange={props.handleChangeDocs1}
            value={props.docs1.birthday}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>บัตรหมดอายุ</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="endDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.endDate}
          />
        </InputColumn>
      </RowContainerInput>
      <LabelSecond>
        ที่อยู่ตามทะเบียนบ้าน (Address as specified in the Household
        Registration Certificate)
      </LabelSecond>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>เลขที่ (House No.)</LabelModel>
          <InputRow
            type="text"
            placeholder="เลขที่"
            name="houseNo"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.houseNo}
          />
          {props.errorsPage1.houseNo && (
            <ErrorsText>{props.errorsPage1.houseNo}</ErrorsText>
          )}
        </InputColumn>
        <InputColumn>
          <LabelModel>หมู่ที่ (Moo)</LabelModel>
          <InputRow
            type="text"
            placeholder="หมู่ที่"
            name="moo"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.moo}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>อาคาร/หมู่บ้าน (Building No.)</LabelModel>
          <InputRow
            type="text"
            placeholder="หมู่บ้าน "
            name="building"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.building}
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>ตรอก/ซอย (Soi)</LabelModel>
          <InputRow
            type="text"
            placeholder="ตรอก/ซอย"
            name="soi"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.soi}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>ถนน (Street)</LabelModel>
          <InputRow
            type="text"
            placeholder="ถนน"
            name="street"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.street}
          />
        </InputColumn>
      </RowContainerInput>
      <RowInputAutoaddress>
        <InputColumn>
          <LabelModel>แขวง (Sub District)</LabelModel>
          <InputAddress
            style={{
              width: "155%",
              height: "3rem",
              border: "1px solid #545859",
              outlineStyle: "none",
              borderRadius: "8px",
              color: "#3c354e",
            }}
            placeholder="แขวง / ตำบล"
            address="subdistrict"
            name="subdistrict"
            value={props.subdistrict}
            onChange={(e) => props.setSubDistrict(e.target.value)}
            onSelect={props.onSelect}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>เขต/อำเภอ (District)</LabelModel>
          <InputAddress
            style={{
              width: "155%",
              height: "3rem",
              border: "1px solid #545859",
              borderRadius: "8px",
              outlineStyle: "none",
              color: "#3c354e",
            }}
            placeholder="เขต / อำเภอ"
            address="district"
            name="district"
            value={props.district}
            onChange={(e) => props.setDistrict(e.target.value)}
            onSelect={props.onSelect}
          />
        </InputColumn>
      </RowInputAutoaddress>
      {props.errorsPage1.subdistrict && (
        <ErrorsText>{props.errorsPage1.subdistrict}</ErrorsText>
      )}
      <RowInputAutoaddress>
        <InputColumn>
          <LabelModel>จังหวัด (Province)</LabelModel>
          <InputAddress
            style={{
              width: "155%",
              height: "3rem",
              border: "1px solid #545859",
              borderRadius: "8px",
              outlineStyle: "none",
              color: "#3c354e",
            }}
            placeholder="จังหวัด"
            address="province"
            name="province"
            value={props.province}
            onChange={(e) => props.setProvince(e.target.value)}
            onSelect={props.onSelect}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>รหัสไปรษณีย์ (Postal Code)</LabelModel>
          <InputAddress
            style={{
              width: "155%",
              height: "3rem",
              border: "1px solid #545859",
              borderRadius: "8px",
              outlineStyle: "none",
              color: "#3c354e",
            }}
            placeholder="เลขไปรษณีย์"
            address="zipcode"
            name="zipcode"
            value={props.zipcode}
            onChange={(e) => props.setZipcode(e.target.value)}
            onSelect={props.onSelect}
          />
        </InputColumn>
      </RowInputAutoaddress>
      {props.errorsPage1.province && (
        <ErrorsText>{props.errorsPage1.province}</ErrorsText>
      )}
      <LabelModel>หมายเลขติดต่อ (Mobile/Telephone)</LabelModel>
      <Input
        type="text"
        placeholder="เบอร์โทร"
        name="phoneNumber"
        onChange={props.handleChangeAddress}
        value={props.docsAddress.phoneNumber}
      />
      {props.errorsPage1.phoneNumber && (
        <ErrorsText>{props.errorsPage1.phoneNumber}</ErrorsText>
      )}
      <LabelModel>อีเมล์ (E-mail)</LabelModel>
      <Input
        type="text"
        placeholder="อีเมล์"
        name="email"
        onChange={props.handleChangeAddress}
        value={props.docsAddress.email}
      />
      {props.errorsPage1.email && (
        <ErrorsText>{props.errorsPage1.email}</ErrorsText>
      )}
    </>
  )
}

export default FormIndividual
