import axios from "axios"
import fileDownload from "js-file-download"
import { saveAs } from "file-saver"
const BASE_URL = "https://api.ntca.co.th/v1"

const signup = (values) => {
  const data = {
    email: values.email,
    password: values.password,
    firstName: values.firstName,
    lastName: values.lastName,
    address: {
      postCode: values.postcode,
      buildingName: values.buildingName,
      houseNo: values.houseNo,
      street: values.street,
      district: values.district,
      subDistrict: values.subDistrict,
      province: values.province,
      moo: values.moo,
      village: values.village,
      alley: values.alley,
    },
  }
  return axios.post(BASE_URL + "/register", data).then((response) => {
    if (response.data) {
      localStorage.setItem("token", response.data)
    }

    return response.data
  })
}

const login = (email, password) => {
  return axios
    .post(BASE_URL + "/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token)
      }

      return response.data
    })
}
const ForgotPassword = (email) => {
  return axios
    .post(BASE_URL + "/customers/forgot-password", {
      email,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token)
      }

      return response.data
    })
}

const logout = () => {
  localStorage.removeItem("token")
}

const getCurrentUser = () => {
  return localStorage.getItem("token")
}

const getUserData = (token) => {
  return axios.get(BASE_URL + "/profile/info", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const getDownloadPDF = (token, id) => {
  return axios
    .get(BASE_URL + "/download/pdf?documentId=" + id, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      console.log(res.data)
      fileDownload(res.data, "certificate.pdf")
    })
}
const getDownloadFile = (token, id) => {
  return axios
    .get(BASE_URL + "/download/files?documentId=" + id, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/zip",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      console.log(res.data)
      saveAs(res.data, "file.zip")
    })
}
const updateName = (token, firstName, lastName) => {
  const data = { firstName: firstName, lastName: lastName }
  return axios.put(BASE_URL + "/profile", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const updateAddress = (
  token,
  postcode,
  buildingName,
  houseNo,
  street,
  district,
  subDistrict,
  province,
  countryID,
  moo,
  village,
  alley
) => {
  const data = {
    address: {
      postCode: postcode,
      buildingName: buildingName,
      houseNo: houseNo,
      street: street,
      district: district,
      subDistrict: subDistrict,
      province: province,
      countryID: countryID,
      moo: moo,
      village: village,
      alley: alley,
    },
  }
  return axios.put(BASE_URL + "/profile", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const updatePwd = (token, password) => {
  const data = { newPassword: password }
  return axios.post(BASE_URL + "/reset-password", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

const getDocuments = (token) => {
  return axios.get(BASE_URL + "/documents", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const getDocumentId = (token, id) => {
  return axios.get(BASE_URL + "/documents/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const searchDocuments = (token, search) => {
  return axios.get(BASE_URL + "/search/documents?search=" + search, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const CreateDocs = (
  token,
  docs1,
  docsAddress,
  isSendToEmail,
  isSendToAddress,
  isOther,
  isOtherAddress,
  isPersonal,
  isWebServer,
  personalCertificate,
  webServerCertificate,
  project1,
  project2,
  project3,
  p3Details,
  project4,
  p4Details,
  project5,
  project6,
  p6Details,
  project7,
  isforIndividual,
  isjuristicPerson,
  isindividualAndJuristic,
  isjuristicAndRank,
  p7forIndividual,
  p7juristicPerson,
  p7individualAndJuristic,
  p7juristicAndRank
) => {
  const data = {
    fullNameTH: docs1.fullNameTH,
    fullNameEN: docs1.fullNameEN,
    createType: docs1.createType,
    certificateType: docs1.certificateType,
    objective: docs1.objective,
    juristicPersonOrOrganizationTH: docs1.juristicPersonOrOrganizationTH,
    juristicPersonOrOrganizationEN: docs1.juristicPersonOrOrganizationEN,
    taxNo: docs1.taxNo,
    businessType: docs1.businessType,
    registrationDate: docs1.registrationDate,
    cardId: docs1.cardId,
    startDate: docs1.startDate,
    endDate: docs1.endDate,
    passport: docs1.passport,
    passportExpire: docs1.passportExpire,
    occupation: docs1.occupation,
    birthday: docs1.birthday,
    officeLocation: docs1.officeLocation,
    addressFromCertificate: {
      headOffice: docsAddress.headOffice,
      branch: docsAddress.branch,
      branchNo: docsAddress.branchNo,
      houseNo: docsAddress.houseNo,
      moo: docsAddress.moo,
      building: docsAddress.building,
      soi: docsAddress.soi,
      street: docsAddress.street,
      subDistrict: docsAddress.subDistrict,
      district: docsAddress.district,
      province: docsAddress.province,
      postalCode: docsAddress.postalCode,
      nameCoordinator: docsAddress.nameCoordinator,
      phoneNumber: docsAddress.phoneNumber,
      email: docsAddress.email,
    },
    serviceDescription: {
      personalCertificate: {
        isCheck: isPersonal,
        email: personalCertificate.email,
        year: personalCertificate.pyear,
      },
      webServerCertificate: {
        isCheck: isWebServer,
        commonName: webServerCertificate.commonName,
        organizationUnit: webServerCertificate.organizationUnit,
        organization: webServerCertificate.organization,
        locality: webServerCertificate.locality,
        country: webServerCertificate.country,
        year: webServerCertificate.wyear,
      },
    },
    informingCharge: {
      sendToEmail: isSendToEmail,
      sendToAddress: isSendToAddress,
      otherMailingAddress: {
        isCheck: isOther,
        houseNo: isOtherAddress.houseNo,
        postCode: isOtherAddress.postCode,
        building: isOtherAddress.building,
        street: isOtherAddress.street,
        moo: isOtherAddress.moo,
        alley: isOtherAddress.alley,
        subDistrict: isOtherAddress.subDistrict,
        district: isOtherAddress.district,
        province: isOtherAddress.province,
        phoneNumber: isOtherAddress.phoneNumber,
        fax: isOtherAddress.fax,
      },
    },
    project1: {
      isCheck: project1,
      name: "none",
    },
    project2: {
      isCheck: project2,
      name: "none",
    },
    project3: {
      isCheck: project3,
      name: p3Details,
    },
    project4: {
      isCheck: project4,
      name: p4Details.name,
      idCard: p4Details.idCard,
      expireDate: p4Details.expireDate,
    },
    project5: {
      isCheck: project5,
      name: "none",
    },
    project6: {
      isCheck: project6,
      name: p6Details,
    },
    project7: {
      isCheck: project7,
      name: "none",
      forIndividual: {
        check: isforIndividual,
        email: p7forIndividual.email,
        certificate: p7forIndividual.certificate,
        idCard: p7forIndividual.idCard,
        province: p7forIndividual.province,
      },
      juristicPerson: {
        check: isjuristicPerson,
        province: p7juristicPerson.province,
        juristicName: p7juristicPerson.juristicName,
        juristicUID: p7juristicPerson.juristicUID,
      },
      individualAndJuristic: {
        check: isindividualAndJuristic,
        functionaryEmail: p7individualAndJuristic.functionaryEmail,
        functionaryName: p7individualAndJuristic.functionaryName,
        subdivision: p7individualAndJuristic.subdivision,
        juristicName: p7individualAndJuristic.juristicName,
        juristicUID: p7individualAndJuristic.juristicUID,
        idCard: p7individualAndJuristic.idCard,
        province: p7individualAndJuristic.province,
      },
      juristicAndRank: {
        check: isjuristicAndRank,
        name: p7juristicAndRank.name,
        subdivision: p7juristicAndRank.subdivision,
        juristicName: p7juristicAndRank.juristicName,
        juristicUID: p7juristicAndRank.juristicUID,
        province: p7juristicAndRank.province,
      },
    },
  }
  return axios.post(BASE_URL + "/documents", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const revokeDocs = (token, docs1) => {
  const data = {
    certificateNo: docs1.certificateNo,
    certificateType: docs1.certificateType,
    objective: docs1.objective,
    juristicPersonOrOrganizationTH: docs1.juristicPersonOrOrganizationTH,
    juristicPersonOrOrganizationEN: docs1.juristicPersonOrOrganizationEN,
    createType: docs1.createType,
    taxNo: docs1.taxNo,
    fullNameTH: docs1.fullNameTH,
    fullNameEN: docs1.fullNameEN,
    cardId: docs1.cardId,
    startDate: docs1.startDate,
    endDate: docs1.endDate,
    birthday: docs1.birthday,
    passport: docs1.passport,
    passportExpire: docs1.passportExpire,
    officeLocation: docs1.officeLocation,
  }
  return axios.post(BASE_URL + "/documents/revoke", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const uploadFile = (token, docsId, documentType, baseFile) => {
  const data = {
    documentId: docsId,
    documentType: documentType,
    base64: baseFile,
    type: "pdf",
  }
  return axios.post(BASE_URL + "/upload/file", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

const authService = {
  signup,
  login,
  logout,
  getCurrentUser,
  getUserData,
  getDocuments,
  searchDocuments,
  updateName,
  updatePwd,
  updateAddress,
  ForgotPassword,
  CreateDocs,
  uploadFile,
  revokeDocs,
  getDocumentId,
  getDownloadPDF,
  getDownloadFile,
}

export default authService
