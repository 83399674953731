import "./App.css"
import React from "react"
import Home from "./pages/index"
import { BrowserRouter as Routers, Routes, Route } from "react-router-dom"
import LoginPage from "./pages/login"
import RegisterPage from "./pages/register"
import PolicyPage from "./pages/policy"
import ForgotPasswordPage from "./pages/forgotpassword"
import SubmitPage from "./pages/submit"
import RevokePage from "./pages/revoke"
import ServicePage from "./pages/service"
import CheckStatusPage from "./pages/checkstatus"
import ProfilePage from "./pages/profile"
import DetailsPage from "./pages/details"

const App = () => {
  return (
    <Routers>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/signin" exact element={<LoginPage />} />
        <Route path="/register" exact element={<RegisterPage />} />
        <Route path="/policy" exact element={<PolicyPage />} />
        <Route path="/forgotpassword" exact element={<ForgotPasswordPage />} />
        <Route path="/profile" exact element={<ProfilePage />} />
        <Route path="/service" exact element={<ServicePage />} />
        <Route path="/submit" exact element={<SubmitPage />} />
        <Route path="/revoke" exact element={<RevokePage />} />
        <Route path="/checkstatus" exact element={<CheckStatusPage />} />
        <Route path="/details/:id" exact element={<DetailsPage />} />
      </Routes>
    </Routers>
  )
}

export default App
