import styled from "styled-components"
import beforeFooter from "../../images/beforeFooter.png"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  min-height: 80vh;
  justify-content: center;
`
export const MainContainer = styled.div`
  margin-bottom: 1rem;
  min-width: 50vw;
  min-height: 90px;
  background: #fff;
  box-shadow: 0 8px 32px 0 #d6d6d6;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 8px;
  color: #000;
  padding-bottom: 1rem;
`
export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-left: 5%;
  margin-right: 5%;
`
export const RowBtn = styled.div`
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 30%;
  @media only screen and (max-width: 540px) {
    flex-direction: column;
    margin-right: 0;
  }
`

export const WelcomeText = styled.h2`
  margin-top: 8rem;
  margin-bottom: 1rem;
  font-weight: 500;
`

export const HeadText = styled.h3`
  font-weight: 400;
  font-size: 18px;
  color: #212529;
`
export const TitleText = styled.p`
  font-weight: 200;
  font-size: 18px;
  color: #212529;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`
export const StatusText = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #00bc20;
`
export const BtnEdit = styled.button`
  text-align: center;
  white-space: nowrap;
  background: none;
  color: #ffd100;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  border: none;
  cursor: pointer;
`
export const BtnSave = styled.button`
  margin-left: 1.5rem;
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }

  @media only screen and (max-width: 540px) {
    margin-top: 1.5rem;
    margin-left: 0;
  }
`
export const BtnCancel = styled.button`
  border-radius: 8px;
  background: none;
  text-align: center;
  border: 1px solid #ffd100;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #ffd100;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #ffd100;
  }
`
export const Input = styled.input`
  margin-top: 1.5rem;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const BeforeFooter = styled.div`
  background: #f8f9fa;
  background-image: url(${beforeFooter});
  max-width: 100%;
  height: 141px;
`
export const ErrorsText = styled.p`
  margin-left: 22%;
  color: red;
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
`
