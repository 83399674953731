import React, { useState, useEffect, useCallback } from "react"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  RowContainer,
  Input,
  BtnEdit,
  BeforeFooter,
  HeadText,
  TitleText,
  BtnSave,
  BtnCancel,
  RowBtn,
} from "./profileElements"

const Profile = () => {
  const navigate = useNavigate()
  const [isShowName, setShowName] = useState(true)
  const [isShowAddress, setShowAddress] = useState(true)
  const [isShowPassword, setShowPassword] = useState(true)
  const [, setCurrentUser] = useState(undefined)
  const token = localStorage.getItem("token")

  const [values, setValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    postcode: "",
    buildingName: "",
    houseNo: "",
    street: "",
    district: "",
    subDistrict: "",
    province: "",
    countryID: "",
    moo: "",
    village: "",
    alley: "",
  })
  const handleChange = (e) => {
    const { name, value } = e.target
    console.log(name, value)
    setValues({
      ...values,
      [name]: value,
    })
  }

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/signin")
      window.location.reload()
    }
  }, [navigate])
  const fetchData = useCallback(async () => {
    await authService.getUserData(token).then((response) => {
      let newData = response.data
      setValues({
        email: newData.email,
        firstName: newData.firstName,
        lastName: newData.lastName,
        postcode: newData.address.postcode,
        buildingName: newData.address.buildingName,
        houseNo: newData.address.houseNo,
        street: newData.address.street,
        district: newData.address.district,
        subDistrict: newData.address.subDistrict,
        province: newData.address.province,
        countryID: newData.address.countryID,
        moo: newData.address.moo,
        village: newData.address.village,
        alley: newData.address.alley,
      })
    })
  }, [token])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const UpdateName = async (e) => {
    e.preventDefault()
    try {
      const response = await authService
        .updateName(token, values.firstName, values.lastName)
        .then(
          () => {
            setShowName(!isShowName)
            window.location.reload()
          },
          (error) => {
            console.log(error)
          }
        )
      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }
  const UpdateAddress = async (e) => {
    e.preventDefault()
    try {
      const response = await authService
        .updateAddress(
          token,
          values.postcode,
          values.buildingName,
          values.houseNo,
          values.street,
          values.district,
          values.subDistrict,
          values.province,
          values.countryID,
          values.moo,
          values.village,
          values.alley
        )
        .then(
          () => {
            setShowName(!isShowName)
            window.location.reload()
          },
          (error) => {
            console.log(error)
          }
        )
      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }

  const UpdatePwd = async (e) => {
    e.preventDefault()
    try {
      const response = await authService.updatePwd(token, values.password).then(
        () => {
          setShowName(!isShowName)
          authService.logout()
          navigate("/signin")
          window.location.reload()
        },
        (error) => {
          console.log(error)
        }
      )
      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <HeroContainer>
        <InputContainer>
          <WelcomeText>ข้อมูลผู้ใช้</WelcomeText>
          <MainContainer>
            <RowContainer>
              <InputContainer>
                <HeadText>อีเมล</HeadText>
                <TitleText>{values.email}</TitleText>
              </InputContainer>
            </RowContainer>
          </MainContainer>
          <MainContainer>
            {isShowName ? (
              <RowContainer>
                <InputContainer>
                  <HeadText>ชื่อ-สกุล</HeadText>
                  <TitleText>
                    {values.firstName}&nbsp;&nbsp;{values.lastName}
                  </TitleText>
                </InputContainer>
                <BtnEdit onClick={() => setShowName(!isShowName)}>
                  แก้ไข
                </BtnEdit>
              </RowContainer>
            ) : (
              <RowContainer>
                <InputContainer>
                  <HeadText>ชื่อ-สกุล</HeadText>
                  <Input
                    type="text"
                    placeholder="ชื่อ"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="นามสกุล"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                  <RowBtn>
                    <BtnCancel onClick={() => setShowName(!isShowName)}>
                      ยกเลิก
                    </BtnCancel>
                    <BtnSave onClick={UpdateName}>ตกลง</BtnSave>
                  </RowBtn>
                </InputContainer>
              </RowContainer>
            )}
          </MainContainer>
          <MainContainer>
            {isShowAddress ? (
              <RowContainer>
                <InputContainer>
                  <HeadText>ที่อยู่</HeadText>
                  <TitleText>
                    {values.houseNo},{values.buildingName},{values.alley}
                    ,{values.village},{values.moo},{values.street},
                    {values.subDistrict},{values.district}
                    {values.province},{values.postcode},
                    {values.countryID}
                  </TitleText>
                </InputContainer>
                <BtnEdit onClick={() => setShowAddress(!isShowAddress)}>
                  แก้ไข
                </BtnEdit>
              </RowContainer>
            ) : (
              <RowContainer>
                <InputContainer>
                  <HeadText>ที่อยู่</HeadText>
                  <Input
                    type="text"
                    placeholder="เลขที่บ้าน"
                    name="houseNo"
                    value={values.houseNo}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="อาคาร ชั้น ห้อง"
                    name="buildingName"
                    value={values.buildingName}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="ซอย"
                    name="alley"
                    value={values.alley}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="หมู่บ้าน"
                    name="village"
                    value={values.village}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="หมู่ที่"
                    name="moo"
                    value={values.moo}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="ถนน"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="ตำบล"
                    name="subDistrict"
                    value={values.subDistrict}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="อำเภอ"
                    name="district"
                    value={values.district}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    value={values.province}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="รหัสไปรษณีย์"
                    name="postcode"
                    value={values.postcode}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    placeholder="ประเทศ"
                    name="countryID"
                    value={values.countryID}
                    onChange={handleChange}
                  />
                  <RowBtn>
                    <BtnCancel onClick={() => setShowAddress(!isShowAddress)}>
                      ยกเลิก
                    </BtnCancel>
                    <BtnSave onClick={UpdateAddress}>ตกลง</BtnSave>
                  </RowBtn>
                </InputContainer>
              </RowContainer>
            )}
          </MainContainer>
          <MainContainer>
            {isShowPassword ? (
              <RowContainer>
                <InputContainer>
                  <HeadText>รหัสผ่าน</HeadText>
                  <TitleText>*********</TitleText>
                </InputContainer>
                <BtnEdit onClick={() => setShowPassword(!isShowPassword)}>
                  แก้ไข
                </BtnEdit>
              </RowContainer>
            ) : (
              <RowContainer>
                <InputContainer>
                  <HeadText>รหัสผ่าน</HeadText>
                  <Input
                    id="password"
                    type="password"
                    placeholder="กรอกรหัสผ่านใหม่"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <RowBtn>
                    <BtnCancel onClick={() => setShowPassword(!isShowPassword)}>
                      ยกเลิก
                    </BtnCancel>
                    <BtnSave onClick={UpdatePwd}>ตกลง</BtnSave>
                  </RowBtn>
                </InputContainer>
              </RowContainer>
            )}
          </MainContainer>
        </InputContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </>
  )
}

export default Profile
