export default function validatePage1(
  docs1,
  docsAddress,
  subdistrict,
  district,
  province,
  zipcode
) {
  let errors = {}

  if (!docs1.fullNameTH.trim()) {
    errors.fullNameTH = "กรุณากรอก ชื่อ-สกุล ภาษาไทย"
  }
  if (!docs1.fullNameEN.trim()) {
    errors.fullNameEN = "กรุณากรอก ชื่อ-สกุล ภาษาอังกฤษ"
  }
  if (!docs1.cardId.trim()) {
    errors.cardId = "กรุณากรอกเลขบัตรประชาชน"
  } else if (docs1.cardId.length < 13 || docs1.cardId.length > 13) {
    errors.cardId = "กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก"
  } else if (!docs1.cardId.replace(/\D/g, "")) {
    errors.cardId = "กรุณากรอกตัวเลข"
  }
  if (!docsAddress.houseNo.trim()) {
    errors.houseNo = "กรุณากรอกเลขที่อยู่"
  }
  if (!docsAddress.phoneNumber.trim()) {
    errors.phoneNumber = "กรุณากรอกเบอร์โทรศัพท์"
  }
  if (!docsAddress.email) {
    errors.email = "กรุณากรอกอีเมล"
  } else if (!/\S+@\S+\.\S+/.test(docsAddress.email)) {
    errors.email = "กรุณากรอกอีเมลให้ถูกต้อง"
  }
  if (!subdistrict.trim() || !district.trim()) {
    errors.subdistrict = "กรุณากรอกที่อยู่ แขวง/ตำบล และ เขต/อำเภอ"
  }
  if (!province.trim() || !zipcode.trim()) {
    errors.province = "กรุณากรอกที่อยู่ จังหวัด และ รหัสไปรษณีย์"
  }
  return errors
}
